import { ILicense } from './types';
import { database } from '../database';

export async function fetchCompanyLicenses(
  companyId: string
): Promise<ILicense[]> {
  const req = await database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .get();

  if (!req.empty) {
    const licenses: ILicense[] = req.docs.map(doc => doc.data() as ILicense);
    return licenses;
  }
  return [];
}
