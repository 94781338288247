import makeStyles from '@material-ui/core/styles/makeStyles';

const useCommonStyles = makeStyles(
  theme => ({
    boxShadow: {
      boxShadow: '7px 7px 17px rgba(84, 194, 120, 0.34)'
    },
    backgroundPrimary: {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: 'white',
        backgroundColor: theme.palette.primary.dark
      }
    },
    textPrimary: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    marginVerticalAuto: {
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block'
    },
    spanPrimaryColor: {
      '& span': {
        color: theme.palette.primary.main
      }
    },
    overflowXhidden: {
      overflowX: 'hidden'
    },
    textTransformUppercase: {
      textTransform: 'uppercase'
    },
    fontWeightNormal: {
      fontWeight: 'normal'
    },
    width250: {
      width: 250
    },
    marginBottom10: {
      marginBottom: 10
    },
    marginAuto: {
      margin: 'auto',
      display: 'block'
    },
    width100per: {
      width: '100%'
    },
    size40: {
      height: '40px',
      width: '40px'
    },
    paddinBottom0: {
      paddingBottom: 0
    },
    marginTop15: { marginTop: 15 },
    width350: { width: 350 },
    flexCenter: {
      display: 'flex',
      placeContent: 'center',
      placeItems: 'center',
      justifyContent: 'center'
    },
    maxWidth450: { maxWidth: 450 },
    textAlignCenter: {
      textAlign: 'center'
    },
    marginBottom15per: {
      marginBottom: '15%'
    },
    positionRelative: {
      position: 'relative'
    },
    positionAbsolute: {
      position: 'absolute'
    },
    flexSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    disabled: {
      filter: 'grayscale(1)'
    },
    form: {
      width: '100%'
    }
  }),
  { index: 1 }
);

export { useCommonStyles as default, useCommonStyles };
