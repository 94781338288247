import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import auth from './auth';
import { ILicenseProduct } from './db/types';
import { ISendMail } from './types';

const REGION = 'europe-west1';
const functions = firebase.app().functions(REGION);

// emails
const sendMail = (mail: ISendMail) => functions.httpsCallable('sendMail')(mail);

// subscriptions
const subscriptionsCreateFreeTrial = (soft: ILicenseProduct, email?: string) =>
  functions.httpsCallable('subscriptionsCreateFreeTrial')({ soft, email });

const subscriptionsUpdateStatus = (data: {
  docId: string;
  newStatus: 'pending_suspension' | 'pending_reactivation';
}) => functions.httpsCallable('subscriptionsUpdateStatus')(data);

const subscriptionDownloadInvoice = async (subscriptionId?: string) => {
  console.log('We doanload the invoice for the subscription: ', subscriptionId);
  if (!subscriptionId) return;
  else {
    const response = await fetch(
      `https://${REGION}-${
        // @ts-ignore
        firebase.app().options.projectId
      }.cloudfunctions.net/subscriptionsDownloadInvoice?subscriptionId=${subscriptionId}`,
      {
        headers: {
          Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`
        }
      }
    );
    const pdfBlob = await response.blob();
    return pdfBlob;
  }
};

const manageDataAdmin = async (
  email: string,
  reason: 'add' | 'view' | 'delete' | 'add_to_company',
  type?: 'writer',
  company?: string
) => {
  //send a http request to firebase function to get the admin data
  //✔  functions[europe-west1-adminCreater]: http function initialized (http://
  // with body {email: string, reason: 'add' | 'view'}
  const response = await fetch(
    `https://${REGION}-${
      // @ts-ignore
      firebase.app().options.projectId
    }.cloudfunctions.net/adminCreater`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`
      },
      body: JSON.stringify({ data: { email, reason, type, company } })
    }
  );
  const data = await response.json();
  return data;
};

const manageLicensesAdmin = async (
  reason: 'add' | 'view' | 'delete' | 'view_all',
  email?: string,
  licenseId?: string,
  quantity?: number,
  soft?: ILicenseProduct,
  isPro?: boolean,
  input?: string
) => {
  //send a http request to firebase function to get the admin data
  //✔  functions[europe-west1-adminCreater]: http function initialized (http://
  // with body {email: string, reason: 'add' | 'view'}
  const response = await fetch(
    `https://${REGION}-${
      // @ts-ignore
      firebase.app().options.projectId
    }.cloudfunctions.net/licenseGifter`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`
      },
      body: JSON.stringify({
        data: {
          email,
          reason,
          quantity,
          soft,
          isPro,
          input,
          licenseId
        }
      })
    }
  );
  const data = await response.json();
  return data;
};

const sendMailSendinblue = async (template: number) => {
  const response = await fetch(
    `https://${REGION}-${
      // @ts-ignore
      firebase.app().options.projectId
    }.cloudfunctions.net/sendMailSendinblue`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await auth.currentUser?.getIdToken()}`
      },
      body: JSON.stringify({
        data: {
          template
        }
      })
    }
  );
  const data = await response.json();
  return data;
};

export {
  sendMail,
  subscriptionsCreateFreeTrial,
  subscriptionsUpdateStatus,
  subscriptionDownloadInvoice,
  manageDataAdmin,
  manageLicensesAdmin,
  sendMailSendinblue
};
