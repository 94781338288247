import { database } from '..';
import { ILicense } from './types';

interface ILicenseKey {
  license: ILicense;
  key: string;
}

// TODO: mettre le tableau en cst
// TODO: généraliser le license Key
//! WARNING: Lui il existe plus, faut delete (vérifié, appelé nulle part bztmR)

function snapCompanyLicenses(
  companyId: string,
  setLicenses: Function
): Function {
  const unsubscribeMethod: Function = database()
    .collection('licenses')
    .where('owner', '==', companyId)
    .where('status', 'in', [
      'active',
      'suspended',
      'cancelled',
      'pending_suspension',
      'pending_reactivation',
      'pending_activation'
    ])
    .orderBy('startedAt', 'desc')
    .onSnapshot(
      (querySnapshot) => {
        const snapLicenses: ILicenseKey[] = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            snapLicenses.push({
              license: doc.data() as ILicense,
              key: doc.id
            });
          }
        });
        setLicenses(snapLicenses);
      },
      (error) => console.log(error)
    );

  return unsubscribeMethod;
}

export { snapCompanyLicenses as default, snapCompanyLicenses };
