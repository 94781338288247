import React, { useState } from 'react';
import {
  makeStyles,
  Popover,
  Fade,
  Button,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AuthMenu from './AuthMenu';

const useStyles = makeStyles(theme => ({
  paperProps: {
    width: 350,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    padding: '36px 29px'
  },
  titleProps: {
    fontSize: 20,
    lineHeight: '23px',
    textAlign: 'left',
    marginBottom: 10
  },
  appbarAccountButton: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: 12,
    height: 128.45806884765625,
    width: 296,
    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',
    '& .MuiButton-label': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    margin: 'auto auto auto auto',
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
      '& .MuiButton-label span': {
        display: 'none'
      },
      height: 39,
      width: 39,
      border: 'none',
      minWidth: 'unset',
      borderRadius: '50%',
      marginLeft: 'unset',
      marginRight: 'unset'
    }
  }
}));

const AuthDialog: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Button
        className={classes.appbarAccountButton}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(event.currentTarget)
        }>
        <img
          src={process.env.PUBLIC_URL + '/images/naviguation-icons/account.svg'}
          alt="Account Icon"
        />
        <span>{t('account')}</span>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        TransitionComponent={Fade}
        PaperProps={{ classes: { root: classes.paperProps } }}>
        <Typography className={classes.titleProps}>{t('account')}</Typography>
        <AuthMenu />
      </Popover>
    </React.Fragment>
  );
};

export { AuthDialog as default, AuthDialog };
