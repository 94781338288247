import { database } from '../database';
import { ICompany } from './types';

export const removeAdmin: Function = async (
  companyId: string,
  email: string
): Promise<void> => await manageCompanyUsers(companyId, email, 'remove');

export const addAdmin: Function = async (
  companyId: string,
  email: string
): Promise<void> => await manageCompanyUsers(companyId, email, 'add');

// TODO: Ajouter un trycatch

async function manageCompanyUsers(
  companyId: string,
  currentEmail: string,
  action: 'add' | 'remove'
) {
  const getCompanyReq = await database()
    .collection('companies')
    .doc(companyId)
    .get();

  const company = getCompanyReq.data() as ICompany;
  const updatedList = [...company.admins];

  if (action === 'add') updatedList.push(currentEmail);
  else {
    console.log("We delete the user from the company's admins");
    //if there is only one admin, we do not delete him
    if (updatedList.length === 1) return;

    const mailIndex = updatedList.findIndex(
      adminEmail => adminEmail === currentEmail
    );
    if (mailIndex > -1) updatedList.splice(mailIndex, 1);

    //we get all the licenses of the company and if the user is in the array heirs of one of them, we remove him
    const getLicensesReq = await database()
      .collection('licenses')
      .where('owner', '==', companyId)
      .get();

    const licenses = getLicensesReq.docs.map(license => license.data());

    licenses.forEach(license => {
      console.log(license);
      //we get the license id, there is no property uid
      var license_find = getLicensesReq.docs.find(
        doc => doc.data().uid === license.uid
      );
      if (license_find !== undefined) license.id = license_find.id;

      const heirs = [...license.heirs];
      console.log('heirs', heirs);
      const mailIndex = heirs.findIndex(
        heirEmail => heirEmail === currentEmail
      );
      if (mailIndex > -1) {
        console.log('we delete the user from the license heirs' + license.id);
        heirs.splice(mailIndex, 1);
        database()
          .collection('licenses')
          .doc(license.id)
          .update({ heirs });
      }
    });
  }

  await database()
    .collection('companies')
    .doc(companyId)
    .update({ admins: updatedList });
}
