import { database } from '..';
import { ILog } from './types';

import { IHistoryDoc } from '../../views/Dashboard/subComponents/HistoryTable';

function snapAdminCompanyLogs(
  updateList: Function,
  getActionLabel: Function
): Function {
  const unsubscriber: Function = database()
    .collection('adminlogs')
    .orderBy('date', 'desc')
    .onSnapshot(
      querySnapshot => {
        const snapLogs: IHistoryDoc[] = [];
        querySnapshot.forEach(doc => {
          const { admin, action, details, date } = doc.data() as ILog;
          snapLogs.push({
            admin,
            action: getActionLabel(action),
            details: details || '/',
            date: date.toDate().toLocaleString('en-GB')
          });
        });
        updateList(snapLogs);
      },
      error => console.log(error)
    );
  return unsubscriber;
}

export { snapAdminCompanyLogs as default, snapAdminCompanyLogs };
