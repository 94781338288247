import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { Routes } from './routes';
import { theme } from './config';
import './config/locales/i18n'; // Initialize i18n
import './css/common.css';
import './css/home.css';
import './css/graph-solver.css';
import './dist/output.css';

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Routes />
  </ThemeProvider>
);

export { App as default, App };
