import { database } from '../database';
import { ILicense, ILicenseProduct } from './types';

export async function appendCollaboratorToLicense(
  companyId: string,
  targetEmail: string,
  soft: ILicenseProduct,
  isServerLicense: boolean = false
): Promise<void | string> {
  //we first look if the target user already has a license
  const targetLicenses = await database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('soft', '==', soft)
    .where('heirs', 'array-contains', targetEmail)
    .get();

  if (!targetLicenses.empty) {
    throw Error('User already has a license');
  }

  //we look for named licenses with no heirs
  const namedLicenses = await database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('soft', '==', soft)
    .where('type', '==', 'named')
    .where('heirs', '==', [])
    .where('status', '==', 'active')
    .get();

  if (!namedLicenses.empty) {
    const licenseId: string = namedLicenses.docs[0].id;
    try {
      await database()
        .collection('licenses')
        .doc(licenseId)
        .set(
          {
            type: 'named',
            heirs: [targetEmail]
          },
          { merge: true }
        );
      return;
    } catch (error) {
      throw Error("Can't update license");
    }
  }

  //we look for server licenses
  const serverLicenses = await database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('soft', '==', soft)
    .where('type', '==', 'server')
    .where('status', '==', 'active')
    .get();

  if (!serverLicenses.empty) {
    const licenseId: string = serverLicenses.docs[0].id;
    const { heirs } = serverLicenses.docs[0].data() as ILicense;
    //if there is not more than 5 users on the server license we add the user
    if (heirs.length < 5) {
      heirs.push(targetEmail);

      await database()
        .collection('licenses')
        .doc(licenseId)
        .set({ heirs }, { merge: true });
      return;
    }
  }

  throw Error('No available licenses');
}
