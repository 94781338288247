import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation, useHistory } from 'react-router-dom';
import { NavLayout, AuthMenu } from '../components';
import { auth } from 'services';
import {
  manageDataAdmin,
  manageLicensesAdmin,
  subscriptionDownloadInvoice
} from 'services';
import { AddField } from './Dashboard/subComponents/ManagementForm/AddField';
import { EmailsList } from './Dashboard/subComponents/ManagementForm/EmailsList';
import { ManagementForm } from './Dashboard/subComponents/ManagementForm';
import { useTranslation } from 'react-i18next';
import { ICollaborator } from './Dashboard/subComponents/ManagementForm/types.ManagementForm';
import { useCommonStyles } from '../css';
import clsx from 'clsx';
import { useStyles } from './Dashboard/styles';
import { Typography } from '@material-ui/core';
import { ILicense, ILicenseProduct, ILicenseContext } from 'services/db/types';
import Appbar from 'components/layouts/NavLayout/Appbar';

//create interface ILicenseSubscription from ILicense
interface ILicenseSubscription extends ILicense {
  subscription_object: {
    owner: string;
    uid: string;
    plan?: string;
    duration: {
      startedAt: {
        _seconds: number;
      };
      expiresIn: {
        _seconds: number;
      };
    };
  };
  uid: string;
  quantity: number;
}

const ViewPlansAdmin: React.FC<{
  type: string;
}> = ({ type }) => {
  var classes = useStyles();

  const { t } = useTranslation('dashboard');
  const [fullList, setFullList] = useState<ILicenseSubscription[]>([]);
  const [list, setList] = useState<ILicenseSubscription[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const groupLicenses = (licenses: ILicenseSubscription[]) => {
    var groupedLicenses = licenses.reduce(
      (acc: ILicenseSubscription[], license: ILicenseSubscription) => {
        var index = acc.findIndex(
          l =>
            l.owner == license.owner &&
            l.soft == license.soft &&
            l.context == license.context
        );
        if (index == -1) {
          license.quantity = 1;
          acc.push(license);
        } else {
          acc[index].quantity += 1;
        }
        return acc;
      },
      []
    );
    return groupedLicenses;
  };

  useEffect(() => {
    manageLicensesAdmin('view_all').then(res => {
      //filter active licenses
      res.result.licenses = res.result.licenses.filter(
        (license: ILicenseSubscription) => {
          return license.context === type && license.status === 'active';
        }
      );
      setFullList(groupLicenses(res.result.licenses));
      setList(groupLicenses(res.result.licenses));
    });
  }, [type]);

  const search = () => {
    const results = fullList.filter(license =>
      license.owner.toLowerCase().includes(searchTerm)
    );
    setList(results);
  };

  const data =
    type == 'team'
      ? [
          [t('subscriptionTable.headers.mail')],
          ['id'],
          [t('subscriptionTable.headers.plan_short')],
          [t('subscriptionTable.headers.status')],
          [t('subscriptionTable.headers.dateStart')],
          [t('subscriptionTable.headers.dateEnd')],
          [t('subscriptionTable.headers.quantity')],
          ['']
        ]
      : [
          [t('subscriptionTable.headers.mail')],
          [t('subscriptionTable.headers.plan_short')],
          [t('subscriptionTable.headers.status')],
          [t('subscriptionTable.headers.dateStart')],
          [t('subscriptionTable.headers.dateEnd')],
          [t('subscriptionTable.headers.quantity')],
          ['']
        ];

  list.forEach(license => {
    var started_at = new Date(
      license.subscription_object.duration.startedAt._seconds * 1000
    );
    var ended_at = new Date(
      license.subscription_object.duration.expiresIn._seconds * 1000
    );
    var plan = license.subscription_object.plan as string;
    var quantity = license.quantity.toString();
    if (type == 'team') {
      data[0].push(license.owner);
      data[1].push(license.subscription_object.owner);
      data[2].push(plan);
      data[3].push(license.status);
      data[4].push(started_at.toLocaleDateString());
      data[5].push(ended_at.toLocaleDateString());
      data[6].push(quantity);
      data[7].push(license.subscription_object.uid);
    } else {
      data[0].push(license.owner);
      data[1].push(plan);
      data[2].push(license.status);
      data[3].push(started_at.toLocaleDateString());
      data[4].push(ended_at.toLocaleDateString());
      data[5].push(quantity);
      data[6].push(license.subscription_object.uid);
    }
  });

  return (
    <div>
      <Appbar />
      <div className="w-10/12 m-auto mt-20">
        <div>
          <input
            type="text"
            name="search"
            id="search"
            className="border border-black"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
          <button
            className="ml-4 border border-black p-2 rounded-md"
            onClick={search}>
            Search
          </button>
        </div>
        <div>
          <div className={clsx(classes.table)}>
            {data.map(
              // TODO: Mettre dans un fichier propre
              ([header, ...content], key_) => (
                <div
                  className={classes.tableLine}
                  key={`subscriptionTable-${key_}`}>
                  <Typography
                    variant="body2"
                    className={classes.tableLineTitle}>
                    {header}
                  </Typography>
                  {content.map((item, key) => {
                    //for the last column, we add a button to delete the license
                    return key_ != data.length - 1 ? (
                      <Typography
                        variant="body2"
                        className={classes.tableLineData}>
                        {item}
                      </Typography>
                    ) : (
                      <button
                        className={classes.tableLineData}
                        onClick={async () => {
                          const pdfBlob = await subscriptionDownloadInvoice(
                            item
                          );
                          if (pdfBlob) {
                            const fileObjectURL = URL.createObjectURL(pdfBlob);
                            window.open(fileObjectURL, '_blank');
                          }
                        }}>
                        {t('subscriptionTable.actions.Download Invoice')}
                      </button>
                    );
                  })}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ViewPlansAdmin as default, ViewPlansAdmin };
