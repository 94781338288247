import { responsiveFontSizes } from '@material-ui/core';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';
import { Shape } from '@material-ui/core/styles/shape';
import { Overrides } from '@material-ui/core/styles/overrides';
import createTheme from '@material-ui/core/styles/createTheme';

const palette = createPalette({
  background: {
    default: '#FFFFFF'
  },
  text: {
    primary: '#000000',
    secondary: '#C4C4C4'
  },
  primary: {
    main: '#54C278',
    light: 'rgba(84, 194, 120, 0.09)',
    dark: '#42ba6a'
  },
  secondary: {
    light: '#eaeaea',
    main: '#C4C4C4',
    dark: '#9e9e9e'
  },
  type: 'light'
});

const typography = createTypography(palette, {
  h1: {
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  h2: {
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  h3: {
    fontSize: '25px',
    fontWeight: 'bold',
    color: palette.primary.main,
    marginBottom: 10
  },
  body1: {
    fontSize: '0.875rem',
    '@media (min-width:600px)': {
      fontSize: 20,
      lineHeight: '23px'
    }
  },
  button: {
    textTransform: 'none'
  }
});

const shape: Shape = {
  borderRadius: 4
};

const overrides: Overrides = {
  MuiCssBaseline: {
    '@global': {
      [['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p span'].join(' span, ')]: {
        color: palette.primary.main
      },
      body: {
        // paddingRight: "0 !important",
      }
    }
  },
  MuiLink: {
    root: {
      color: 'black',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 15,
      '&:hover': {
        color: palette.primary.main
      }
    },
    underlineHover: {
      '&:hover': {
        textDecoration: 'none'
      }
    }
  },
  MuiIconButton: {
    root: {
      color: palette.primary.main
    }
  }
};

const theme = responsiveFontSizes(
  createTheme({
    palette,
    typography,
    shape,
    overrides
  })
);

export { theme as default, theme };
