import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import width from '../../views/Doc/General/General';

interface IHeading {
  imageSrc: string;
  topLeftImage?: {
    src: string;
    left: string;
    top: string;
    width: string;
  };
  bottomRightImage?: {
    src: string;
    right: string;
    top: string;
    width: string;
  };
  breakingPoints: Partial<Theme['breakpoints']['values']>;
  breakingPointsType?: '%' | 'px';
  H1: JSX.Element;
  H2?: JSX.Element;
  variant?: 'product-view' | 'home-view' | 'pricing-view';
  className?: string;
}

const useStyles = makeStyles<
  Theme,
  Pick<
    IHeading,
    | 'imageSrc'
    | 'breakingPoints'
    | 'breakingPointsType'
    | 'variant'
    | 'bottomRightImage'
    | 'topLeftImage'
  >
>(theme => ({
  headingContainer: ({ imageSrc, breakingPoints, breakingPointsType }) => ({
    position: 'relative',
    width: '100%',
    height: 0,
    backgroundSize: '50em',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '25em'
    },
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${imageSrc})`,
    ...Object.entries(breakingPoints).reduce(
      (acc, [breakpoint, number]) => ({
        ...acc,
        [theme.breakpoints.up(
          breakpoint as number | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
        )]: {
          paddingBottom:
            number + (breakingPointsType ? breakingPointsType : '%')
        }
      }),
      {}
    )
  }),
  absoluteCenter: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)'
  },
  headingTextH1: ({ variant }) => ({
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    ...(variant === 'product-view' && {
      fontSize: 20,
      lineHeight: '23px',
      fontWeight: 'unset',
      '& > span': {
        textTransform: 'uppercase'
      },
      [theme.breakpoints.up('sm')]: { fontSize: 50, lineHeight: '59px' }
    }),
    ...(variant === 'home-view' && {
      fontSize: 20,
      lineHeight: '23px',
      fontWeight: 500,
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.375rem',
        lineHeight: '45px',
        maxWidth: 600
      }
    }),
    ...(variant === 'pricing-view' && {
      fontSize: 20,
      lineHeight: '23px',
      fontWeight: 'unset',
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.375rem',
        lineHeight: '45px'
      }
    })
  }),
  headingTextH2: ({ variant }) => ({
    width: 276,
    margin: 'auto',
    ...(variant === 'home-view' && {
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    })
  }),
  bottomRightImage: ({ bottomRightImage }) => ({
    position: 'absolute',
    right: bottomRightImage?.right || '0',
    top: bottomRightImage?.top || '0',
    width: bottomRightImage?.width,
    height: 'auto'
  }),
  topLeftImage: ({ topLeftImage }) => ({
    position: 'absolute',
    top: topLeftImage?.top || '0',
    left: topLeftImage?.left || '0',
    width: topLeftImage?.width,
    height: 'auto'
  })
}));

const Heading: React.FC<IHeading> = ({
  H1,
  H2,
  imageSrc,
  topLeftImage,
  bottomRightImage,
  breakingPoints,
  breakingPointsType,
  variant,
  className
}) => {
  const classes = useStyles({
    imageSrc,
    breakingPoints,
    variant,
    breakingPointsType,
    bottomRightImage,
    topLeftImage
  });
  return (
    <div className={clsx(className, classes.headingContainer)}>
      <div className={classes.absoluteCenter}>
        {bottomRightImage && (
          <img
            className={classes.bottomRightImage}
            src={bottomRightImage?.src}
          />
        )}
        {topLeftImage && (
          <img className={classes.topLeftImage} src={topLeftImage?.src} />
        )}
        <Typography variant="h1" className={classes.headingTextH1}>
          {H1}
        </Typography>
        {H2 && (
          <Typography variant="h2" className={classes.headingTextH2}>
            {H2}
          </Typography>
        )}
      </div>
    </div>
  );
};

export { Heading };
