import { ILicenseProduct as ISoft } from './types';
import { database } from '../database';
import { auth } from '..';

interface CheckFreeTrialAccessProps {
  isAllowed: boolean;
  email: string;
}

export async function checkFreeTrialAccess(
  soft: ISoft
): Promise<CheckFreeTrialAccessProps> {
  const email: string = auth.currentUser?.email || '';

  if (email === '') return { isAllowed: false, email: '' };
  else {
    try {
      const targettedDoc = await database()
        .collection('licenses')
        .where('soft', '==', soft)
        .where('heirs', 'array-contains', email)
        .where('status', '==', 'active')
        .get();

      return { isAllowed: targettedDoc.empty, email };
    } catch (e) {
      return { isAllowed: false, email: '' };
    }
  }
}
