import database from '../database';

// TODO: Mettre des trycatch et mieux découper ça

async function updateLicenseStatus(
  updatedStatus: string,
  subscription?: string
): Promise<void> {
  if (subscription) {
    const req = await database()
      .collection('licenses')
      .where('subscription', '==', subscription)
      .get();

    // Updates licenses in db
    if (!req.empty) {
      Promise.all(
        req.docs.map((doc) => {
          return !doc.exists
            ? null
            : new Promise<void>((res, rej) => {
                database()
                  .collection('licenses')
                  .doc(doc.id)
                  .update({ status: updatedStatus })
                  .then(res)
                  .catch(rej);
              });
        })
      );
    }
  }
}

export { updateLicenseStatus as default, updateLicenseStatus };
