import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Icon
} from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from '../../css';
import { auth } from '../../services';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  dialogBox: {
    background: 'white',
    borderRadius: '29px',
    overflow: 'hidden',
    width: '350px',
    height: '304px'
  },
  specButtonFont: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    padding: 0
  },
  generalButtonStyle2: {
    background: 'white',
    border: '2px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: '36px'
  },
  specButtonWidth: {
    width: '243px',
    padding: '12px 0'
  },
  paddingContainer: {
    marginLeft: '53px'
  },
  individual: {
    display: 'flex'
  },
  individual1: {
    paddingLeft: '7px',
    display: 'flex',
    marginBottom: '40px'
  },
  betMargin: {
    marginLeft: '24px'
  },
  betMargin1: {
    marginLeft: '26px',
    marginTop: '7px'
  },
  greenLetter: {
    color: 'rgb(84, 194, 120)'
  },
  titleCss: {
    paddingBottom: '29px',
    paddingTop: '29px',
    paddingLeft: '36px',
    fontSize: '20px',
    '& h2': {
      fontSize: '20px',
      fontWeight: 400
    }
  },
  hoverPointer: {
    '&:hover': { color: 'rgb(58, 135, 84)' }
  }
}));

export interface IAuthSignedIn {
  onSignOut?: () => void;
}

const AuthSignedIn: React.FC<IAuthSignedIn> = ({ onSignOut }) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation('common');
  const history = useHistory();

  const [user, setUser] = useState(auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => setUser(user));
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <div className="mx-auto">
        <button
          className="flex flex-row bg-white rounded-full items-center mt-3 border border-green rounded-full xl:mr-14"
          onClick={() => history.push('/dashboard')}>
          <p className="text-green text-sm font-bold font-roboto mx-auto p-3">
            Dashboard
          </p>
          <div className="mr-5">
            <svg
              width="38"
              height="36"
              viewBox="0 0 38 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <ellipse
                cx="19.135"
                cy="18.1389"
                rx="18.2619"
                ry="17.6944"
                fill="#54C278"
              />
              <path
                d="M10.2383 26.7593C10.2383 15.4473 28.0319 16.3018 28.0319 26.7593"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M24.2215 13.6019C24.2215 16.2775 21.9738 18.5 19.1342 18.5C16.2945 18.5 14.0469 16.2775 14.0469 13.6019C14.0469 10.9262 16.2945 8.70374 19.1342 8.70374C21.9738 8.70374 24.2215 10.9262 24.2215 13.6019Z"
                stroke="white"
                strokeWidth="2"
              />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export { AuthSignedIn as default, AuthSignedIn };
