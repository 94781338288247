import React from 'react';
import { makeStyles, Button, List, Icon, ListItem } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from '../../css';
import { auth, IUserCredential, IOAuthProviderLabel } from '../../services';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  buttonStyle: {
    border: '2px solid #E5E5E5',
    borderRadius: 36,
    '&:not(:last-child)': {
      marginBottom: 20
    },
    justifyContent: 'center'
  }
}));

export interface IAuthSignIn {
  onSignIn?: (userCredentials: IUserCredential) => void;
  type?: 'appbar' | 'dialog';
}

const Auth: React.FC<IAuthSignIn> = ({ onSignIn, type }) => {
  const history = useHistory();
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { t } = useTranslation('common');

  const signIn = async (providerLabel: IOAuthProviderLabel) => {
    const userCredentials = await auth.signIn(providerLabel);
    if (onSignIn) {
      // @ts-ignore
      onSignIn(userCredentials);
    }
  };

  const buttons: {
    providerLabel: IOAuthProviderLabel;
    title: string;
    image: string;
  }[] = [
    {
      providerLabel: 'google.com',
      title: t('connectWith', { authProvider: 'Google' }),
      image: '/images/auth/google.svg'
    },
    {
      providerLabel: 'microsoft.com',
      title: t('connectWith', { authProvider: 'Microsoft' }),
      image: '/images/auth/microsoft.svg'
    }
  ];
  if (type === 'appbar')
    return (
      <div className="mx-auto">
        <button
          className="flex flex-row bg-green rounded-full items-center mt-3"
          onClick={() => history.push('/login')}>
          <p className="text-white text-base font-bold font-roboto mx-auto p-3">
            Se connecter
          </p>
          <div className="mr-5">
            <svg
              width="38"
              height="36"
              viewBox="0 0 38 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <ellipse
                cx="19.135"
                cy="18.1389"
                rx="18.2619"
                ry="17.6944"
                fill="white"
              />
              <path
                d="M10.2383 26.7593C10.2383 15.4473 28.0319 16.3018 28.0319 26.7593"
                stroke="#54C278"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M24.2215 13.6019C24.2215 16.2775 21.9738 18.5 19.1342 18.5C16.2945 18.5 14.0469 16.2775 14.0469 13.6019C14.0469 10.9262 16.2945 8.70374 19.1342 8.70374C21.9738 8.70374 24.2215 10.9262 24.2215 13.6019Z"
                stroke="#54C278"
                stroke-width="2"
              />
            </svg>
          </div>
        </button>
      </div>
    );
  return (
    <div className="mx-auto mt-5">
      <div className="flex flex-col">
        {buttons.map(({ title, image, providerLabel }, key) => (
          <div className="mt-3 w-96 mx-auto">
            <ListItem
              component={Button}
              className={clsx(
                commonClasses.fontWeightNormal,
                classes.buttonStyle
              )}
              key={key}
              startIcon={
                <Icon>
                  <img src={image} alt="Auth Provider" />
                </Icon>
              }
              onClick={() => signIn(providerLabel)}>
              <p className="font-roboto text-base p-1">{title}</p>
            </ListItem>
          </div>
        ))}
      </div>
    </div>
  );
};

export { Auth as default, Auth };
