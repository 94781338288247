import React from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Appbar from './Appbar';
import Footer from './Footer';
import ContentContainer from './ContentContainer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    '& > header': {
      flexShrink: 0
    },
    '& > footer': {
      flexShrink: 0
    }
  },
  main: {
    flex: '1 0 auto'
  }
}));

export interface INavLayout {
  disableContentContainer?: boolean;
  children?: any;
  classNames?: {
    root?: string;
    main?: string;
  };
}

export const NavLayout: React.FC<INavLayout> = ({
  children,
  disableContentContainer,
  classNames
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, classNames?.root)}>
      <Appbar />
      <main className={clsx(classes.main, classNames?.main)}>
        {disableContentContainer ? (
          children
        ) : (
          <ContentContainer>{children}</ContentContainer>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default NavLayout;
