import { database } from '..';

export const checkTookFreeTrial = (
  uid: string,
  updateTookTrial: Function
): Function => {
  console.log('checking if user took trial');
  const unsubscriber: Function = database()
    .collection('trial')
    //where the document id is the user id
    .where('uid', '==', uid)
    .onSnapshot(docs => {
      if (docs.docs.length > 0) {
        console.log('user took trial');
        updateTookTrial(true);
      } else {
        console.log('user did not take trial');
        updateTookTrial(false);
      }
    });
  return unsubscriber;
};
