import firebase from 'firebase/compat/app';

 const env = process.env.REACT_APP_QUICKCELLS_ENV;
//const env = 'PRODUCTION';

firebase.initializeApp(
  env === 'PRODUCTION'
    ? {
        apiKey: 'AIzaSyD7z-rMBHIvv4RH7pfUk3v8i-x8WBvgI7w',
        authDomain: 'quickcells.co',
        databaseURL: 'https://cc1c-10b1a.firebaseio.com',
        projectId: 'cc1c-10b1a',
        storageBucket: 'cc1c-10b1a.appspot.com',
        messagingSenderId: '156287778355',
        appId: '1:156287778355:web:781b87e0b6e99dac6f2d46',
        measurementId: 'G-REVKQH2P9B'
      }
    : {
        apiKey: 'AIzaSyAUNwalSow0DCOpaMnkb4NiMv7OTBu3IlE',
        authDomain: 'quickcells-dev.firebaseapp.com',
        databaseURL: 'https://quickcells-dev.firebaseio.com',
        projectId: 'quickcells-dev',
        storageBucket: 'quickcells-dev.appspot.com',
        messagingSenderId: '89460856380',
        appId: '1:89460856380:web:4018836b70e763f1159539'
      }
);

// if (env === 'PRODUCTION') {
//   firebase.analytics();
// }
