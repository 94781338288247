import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dualRing: {
    display: 'inline-block',
    width: '80px',
    height: '80px',
    '&:after': {
      content: '" "',
      display: 'block',
      width: '64px',
      height: '64px',
      margin: '8px',
      borderRadius: '50%',
      border: '6px solid #54C278',
      borderColor: '#54C278 transparent #54C278 transparent',
      animation: `lds-dual-ring 1.2s ${theme.transitions.easing.easeIn} infinite`
    }
  },
  '@keyframes lds-dual-ring': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}));

export const SuspenseFallback = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <div className={classes.dualRing}></div>
    </div>
  );
};
