import React, { useEffect, useState } from 'react';
import AuthSignIn, { IAuthSignIn } from '../components/auth/AuthSignIn';
import { auth } from '../services';
import { useHistory } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fade
} from '@material-ui/core';

const auth_firebase = getAuth();
const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const resetPassword = (email: string) => {
    sendPasswordResetEmail(auth_firebase, email)
      .then(() => {
        setDialogOpen(true);
      })
      .catch(error => {});
  };
  const history = useHistory();

  const [isDialogOpen, setDialogOpen] = useState(false);

  return (
    <div className="flex flex-col flex flex-col mx-auto mt-40 text-2xl">
      <div className="flex flex-row mx-auto">
        <p>Reset your</p>
        <p className="text-green ml-2">Password</p>
        <p className="ml-2">!</p>
      </div>
      <p className="font-roboto text-xs mx-auto mt-4 w-80">
        Enter your email and we'll send you instructions on how to reset your
        password.
      </p>

      <Dialog
        maxWidth="xl"
        open={isDialogOpen}
        TransitionComponent={Fade}
        keepMounted
        onClose={() => setDialogOpen(false)}>
        <div className="p-6 rounded-full">
          <DialogContent>
            <DialogContentText color="textPrimary">
              <p className="text-base">
                We've sent an email with instructions on how to reset your
                password to {email}.
              </p>
            </DialogContentText>
          </DialogContent>
        </div>
        <DialogActions>
          <div className="m-auto mb-10">
            <button
              onClick={() => {
                setDialogOpen(false);
                setEmail('');
                history.push('/');
              }}>
              <svg
                width="60"
                height="60"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle
                  cx="15.5"
                  cy="15.5"
                  r="15"
                  fill="white"
                  stroke="#E7E7E7"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21.5289 10.2109C20.9141 9.5961 19.9172 9.5961 19.3024 10.2109L15.7773 13.736L12.2523 10.2109C11.6374 9.5961 10.6406 9.5961 10.0258 10.2109C9.41098 10.8257 9.41098 11.8226 10.0258 12.4374L13.5509 15.9625L10.2111 19.3023C9.59625 19.9171 9.59624 20.9139 10.2111 21.5287C10.8259 22.1436 11.8227 22.1436 12.4375 21.5287L15.7773 18.1889L19.1172 21.5287C19.732 22.1436 20.7288 22.1436 21.3436 21.5287C21.9584 20.9139 21.9584 19.9171 21.3436 19.3023L18.0038 15.9625L21.5289 12.4374C22.1437 11.8226 22.1437 10.8257 21.5289 10.2109Z"
                  fill="#54C278"
                  fillOpacity="0.5"
                />
              </svg>
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <input
        className="px-6 py-2 flex flex-row rounded-full border border-grey-light mx-auto w-96 mt-6 text-xl h-14 items-center"
        placeholder="Email address"
        onChange={e => setEmail(e.target.value)}
        value={email}
      />
      <button
        className="bg-green px-6 py-2 flex flex-row rounded-full border border-grey-light mx-auto w-96 mt-6 text-xl h-14 items-center"
        onClick={() => resetPassword(email)}>
        <p className="text-white font-bold font-roboto mx-auto">
          Send instructions
        </p>
      </button>
    </div>
  );
};

export { ResetPassword as default, ResetPassword };
