import makeStyles from '@material-ui/core/styles/makeStyles';

const useBackgroundStyles = makeStyles(
  theme => ({
    // Background green dots (:before)
    greenDots: {
      position: 'relative',
      '&:before': {
        transform: 'rotate(-34.65deg)',
        content: '""',
        zIndex: '-1',
        position: 'absolute',
        background: 'url(/images/background-images/dots.svg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        opacity: 0.1
      }
    },
    topLeft: {
      '&:before': {
        width: 177.34,
        height: 113.04,
        top: -30,
        left: -50,
        [theme.breakpoints.up('md')]: {
          top: -38,
          left: -100,
          width: 248.64,
          height: 158.48
        }
      }
    },
    bottomRight: {
      '&:before': {
        width: 177.34,
        height: 113.04,
        bottom: '-25%',
        right: '-5%',
        [theme.breakpoints.up('sm')]: {
          bottom: -30
        }
      }
    },
    size1: {
      '&:before': {
        width: 137,
        height: 87
      }
    },
    size2: {
      '&:before': {
        width: 152,
        height: 97
      }
    },
    removeSm: {
      '&:before': {
        [theme.breakpoints.down('sm')]: {
          content: 'none'
        }
      }
    },
    // Background grey dots (:before)
    greyDots: {
      position: 'relative',
      '&:before': {
        content: '""',
        zIndex: '-1',
        position: 'absolute',
        backgroundImage:
          'url(/images/background-images/background-dots-grey.svg)',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat'
      }
    },
    // Background shadow
    shadow1: {
      '& > img': {
        filter: 'drop-shadow(20px 20px 41px rgba(0, 0, 0, 0.05))'
      }
    },
    shadow2: {
      '& > img': {
        filter: 'drop-shadow(8px 7px 36px rgba(0, 0, 0, 0.1))'
      }
    }
  }),
  { index: 1 }
);

export { useBackgroundStyles as default, useBackgroundStyles };
