import { database } from '../database';
import { ILicenseType } from './types';

export async function deleteLicenseServer(companyId: string, soft: string) {
  const serverLicenses = await database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('status', '==', 'active')
    .where('type', '==', 'server')
    .where('soft', '==', soft)
    .get();

  if (!serverLicenses.empty) {
    const licenseId = serverLicenses.docs[0].id;

    //update from server to unused
    await database()
      .collection('licenses')
      .doc(licenseId)
      .update({ type: 'unused', heirs: [] });
  }
}
