import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

//! METTRE ÇA DANS UN SERVICE PROPRE :@
const database = firebase.firestore;
const setFirestoreTimestamp = firebase.firestore.Timestamp;
type ITimeUnit = 'now' | 'monthly' | 'yearly' | 'free';
type ITimestamp = firebase.firestore.Timestamp;

function getUpdatedDate(timeUnit: ITimeUnit): Date {
  //! Foutre ça dans config
  const freeTrialDays: number = 15;
  const today = new Date(Date.now());
  switch (timeUnit) {
    case 'now':
      return today;
    case 'monthly':
      return new Date(today.setMonth(today.getMonth() + 1));
    case 'yearly':
      return new Date(today.setFullYear(today.getFullYear() + 1));
    case 'free':
      return new Date(today.setDate(today.getDate() + freeTrialDays));
    default:
      return today;
  }
}

const parseDate: Function = (timeUnit: ITimeUnit): ITimestamp =>
  setFirestoreTimestamp.fromDate(getUpdatedDate(timeUnit));

const fromDateToFirestoreTimestamp: Function = (date: string): ITimestamp =>
  setFirestoreTimestamp.fromDate(new Date(date));

export {
  database as default,
  database,
  parseDate,
  fromDateToFirestoreTimestamp
};
