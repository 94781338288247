import { ILicense } from './types';
import { database } from '../database';

export interface ICounters {
  named: ICountersProducts;
  server: ICountersProducts;
}

export interface ICountersProducts {
  graphs: number;
  premium: number;
  solvers: number;
  total: number;
}

export const fetchCompanyLicensesCounters = (
  companyId: string,
  setLicensesCounters: Function
): Function =>
  database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('status', '==', 'active')
    .onSnapshot(snapshots => {
      const counters: ICounters = {
        server: {
          graphs: 0,
          premium: 0,
          solvers: 0,
          total: 0
        },
        named: {
          graphs: 0,
          premium: 0,
          solvers: 0,
          total: 0
        }
      };
      snapshots.forEach(snap => {
        if (snap.exists) {
          const license = snap.data() as ILicense;
          if (license.type) {
            const soft = license.soft;
            counters[license.type][soft] += 1;
            counters[license.type].total += 1;
          }
        }
      });
      console.log('counters', counters);
      setLicensesCounters(counters);
    });
