import { database } from '..';

export const checkIsWriter = (
  uid: string,
  updateIsWriter: Function
): Function => {
  //we check if there is one document in the writers collection which id is the uid of the user
  //function take uid as parameter
  if (!uid) uid = '';
  const unsubscriber: Function = database()
    .collection('writers')
    .doc(uid)
    .onSnapshot(doc => {
      if (doc.exists) {
        updateIsWriter(true);
      } else {
        updateIsWriter(false);
      }
    });
  return unsubscriber;
};
