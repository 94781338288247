import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    border: 'none',
    borderColor: 'white'
  },
  paper: {
    paddingTop: '20px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  modalButton: {
    width: '112px',
    color: 'white',
    borderRadius: '18px',
    background: '#54C278'
  },
  modalBGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: '40px'
  },
  greenLetter: {
    color: 'rgb(84, 194, 120)'
  },
  fullWidth: {
    width: '100%'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  avatarPart: {
    width: '252px',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      backgroundImage: 'none'
    },
    [theme.breakpoints.up('md')]: {
      boxShadow: '15px 0px 70px rgba(84, 194, 120, 0.13)'
    }
  },
  mainPart: {
    width: 'calc(100% - 252px)',
    padding: '60px 0 60px 5%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '30px 0 100px 0'
    }
  },
  mainFullSize: {
    display: 'flex',
    '& > div': {
      width: '100%'
    }
  },
  flexAvatar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch'
  },
  flexMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  },
  avatarContain: {
    width: '100%',
    marginTop: '40px'
  },
  avatarUsername: {
    marginTop: 25,
    overflowWrap: 'anywhere',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  minHeight300: {
    minHeight: 300,
    [theme.breakpoints.up('md')]: {
      height: '100%'
    }
  },
  marginTop57: {
    marginTop: 57
  },
  emitTypoMargin: {
    margin: 0,
    padding: 0
  },
  userNameStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    margin: '25px 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  buttonGroup: {
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  greenButton: {
    background: '#54C278',
    borderRadius: '24px',
    color: 'white',
    marginBottom: '10px',
    // width: "190px",
    width: '161px'
  },
  whiteButton: {
    background: 'white',
    borderRadius: '24px',
    border: '1px solid #C5C5C5',
    boxSizing: 'border-box',
    color: 'black',
    width: '190px'
  },
  commonButtonTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center'
  },
  tableFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    }
  },
  rowFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  helloStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '100px'
    }
  },
  commonTableStyle: {
    background: 'white',
    borderRadius: '12px',
    width: '85%',
    border: '1px solid #54C278',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto'
    }
  },
  commonTableTextStyle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '9px',
    lineHeight: '11px'
  },
  theadStyle: {
    width: '100%',
    color: 'white',
    borderRadius: '12px 12px 0 0',
    background: '#54C278',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '12px 0 0 12px'
    }
  },
  textCenterOrLeft: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left !important',
      paddingLeft: '10%'
    }
  },
  tcontentStyle: {
    width: '100%'
  },
  tr1Width: {
    wordBreak: 'break-word',
    width: '16%',
    textAlign: 'center',
    borderRight: '1px solid #54C278',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
      borderBottom: '1px solid rgba(92, 167, 120, 0.33)'
    },
    [theme.breakpoints.down(360)]: {
      height: '30px'
    }
  },
  tr2Width: {
    wordBreak: 'break-word',
    width: '14%',
    textAlign: 'center',
    borderRight: '1px solid #54C278',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
      borderBottom: '1px solid rgba(92, 167, 120, 0.33)'
    }
  },
  tr3Width: {
    wordBreak: 'break-word',
    width: '11%',
    textAlign: 'center',
    borderRight: '1px solid #54C278',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
      borderBottom: '1px solid rgba(92, 167, 120, 0.33)'
    }
  },
  tr4Width: {
    wordBreak: 'break-word',
    width: '20%',
    textAlign: 'center',
    borderRight: '1px solid #54C278',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
      borderBottom: '1px solid rgba(92, 167, 120, 0.33)'
    }
  },
  tr5Width: {
    wordBreak: 'break-word',
    width: '7%',
    textAlign: 'center',
    borderRight: '1px solid #54C278',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
      borderBottom: '1px solid rgba(92, 167, 120, 0.33)'
    }
  },
  tr6Width: {
    wordBreak: 'break-word',
    width: '17%',
    textAlign: 'center',
    borderRight: '1px solid #54C278',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 0,
      borderBottom: '1px solid rgba(92, 167, 120, 0.33)'
    }
  },
  tr7Width: {
    wordBreak: 'break-word',
    width: '15%',
    textAlign: 'center',
    alignSelf: 'stretch',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  marginBetween: {
    marginTop: '2px',
    marginBottom: '35px'
  },
  downPart: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '85%',
    [theme.breakpoints.down('sm')]: {
      width: '85%',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  greyLetter: {
    // color: '#C4C4C4',
    color: 'black'
  },
  downloadGroup: {
    display: 'inherit'
  },
  positionRel: {
    position: 'relative'
  },
  setBkg: {
    position: 'absolute',
    transform: 'rotate(-34.65deg)',
    left: '-5%',
    top: '-1%',
    width: '109%',
    opacity: 0.06,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      top: '10%',
      height: '250px'
    },
    [theme.breakpoints.down('xs')]: {
      width: '132%',
      top: '-63%',
      height: '274px',
      left: '-18%'
    }
  },
  responsMid: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inherit!important',
      marginRight: '2px',
      marginLeft: '2px'
    }
  },
  responsMob: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inherit'
    }
  },
  responsDes: {
    display: 'inherit',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  caption: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '14px'
  },
  firstTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: '26px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start'
    }
  },
  buttonMargin1: {
    marginLeft: '35px',
    paddingLeft: 0
  },
  licenseCounters: {
    background: '#FFFFFF',
    border: '1px solid rgba(84, 194, 120, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '7px'
  },
  generalButtonStyle2: {
    background: '#FFFFFF',
    border: '1px solid rgba(84, 194, 120, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '7px',
    color: '#54C278 !important'
  },
  licButton: {
    width: '100%',
    marginTop: '15px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
      marginBottom: '40px'
    }
  },
  containerPart: {
    [theme.breakpoints.down('xs')]: {
      '&::after': {
        content: '""',
        display: 'block',
        width: '25%',
        borderBottom: '2px solid #E7E7E7'
      }
    }
  },
  alignSelfToStartOnSm: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'flex-start',
      marginLeft: '35px'
    },
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
      marginLeft: '35px'
    }
  },
  flexNouvel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  inputStyle: {
    width: '322px',
    borderRadius: '5px',
    padding: '10px 17px',
    background: '#FFFFFF',
    border: '2px solid rgba(196, 196, 196, 0.5)',
    [theme.breakpoints.down('sm')]: {
      width: '160px'
    }
  },
  marginCommon: {
    marginBottom: '20px'
  },
  addButton: {
    width: '120px'
  },
  formStyle: {
    borderRight: '0.5px solid rgba(84, 194, 120, 0.5) !important',
    [theme.breakpoints.down('xs')]: {
      borderRight: 'none !important'
    }
  },
  someMargin: {
    paddingRight: '55px !important',
    marginRight: '55px !important',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px !important',
      paddingRight: '0px !important'
    }
  },
  emailPart1: {
    [theme.breakpoints.down(850)]: {
      marginBottom: '20px'
    }
  },
  formPart1: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column'
  },
  serverLicenseBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  ellipseText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  emailPartMargin: {
    marginBottom: '28px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '20px',
      marginBottom: '20px'
    }
  },
  indivEmail: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minWidth: '200px'
  },
  emailMargin: {
    marginBottom: '15px'
  },
  emailMarginOnMd: {
    [theme.breakpoints.up('md')]: {
      marginBottom: '15px'
    }
  },
  lastPart: {
    marginTop: '40px',
    marginBottom: '40px'
  },
  marginBetweentable: {
    marginBottom: '20px'
  },
  specButtonFont: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    paddingLeft: '17px'
  },
  hideOnXs: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  hideOnSm: {
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  specButtonWidth: {
    width: '226px',
    height: '40px',
    border: '2px solid #E5E5E5'
  },
  iconButton: {
    float: 'left',
    position: 'absolute',
    left: '8px'
  },
  emailPart2: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'row',
    [theme.breakpoints.down(1150)]: {
      flexDirection: 'column',
      marginBottom: '40px'
    },
    [theme.breakpoints.down(950)]: {
      flexDirection: 'row',
      marginBottom: 0
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginBottom: '40px'
    }
  },
  emailBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: '30px'
  },
  dash_port_texture: {
    cursor: 'pointer',
    '&:hover': {
      color: '#868585'
    }
  },
  dash_port_texture_A: {
    cursor: 'pointer',
    '&:hover': {
      color: 'rgb(58, 135, 84)'
    }
  },
  cancelStyle: {
    marginLeft: '10px',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.2)'
    },
    [theme.breakpoints.down('lg')]: {
      marginRight: '15px'
    }
  },
  tableContainer: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
      minWidth: 590,
      maxWidth: 1300
    },
    [theme.breakpoints.up('md')]: {
      margin: 'unset'
    }
  },
  tableContainer2: {
    width: '90%',
    margin: 'auto',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '75%',
      minWidth: 590,
      maxWidth: 1300
    },
    [theme.breakpoints.up('md')]: {
      margin: 'unset'
    }
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    borderRadius: 12,
    border: `2px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    marginTop: 50,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginLeft: 0
    }
  },
  tableLine: {
    display: 'flex',
    flexBasis: '25%',
    flexDirection: 'column',
    textAlign: 'center',
    '& > p': {
      borderBottom: '2px solid rgba(92, 167, 120, 0.33)',
      padding: 10,
      width: '100%',
      [theme.breakpoints.up('sm')]: { width: '50%' },
      [theme.breakpoints.up('md')]: {
        width: 'unset',
        borderBottom: 'none'
      }
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      '&:not(:last-child)': {
        borderRight: `2px solid ${theme.palette.primary.main}`
      }
    }
  },
  tableLineTitle: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    textTransform: 'capitalize',
    [theme.breakpoints.up('md')]: {
      height: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  tableLineData: {
    fontSize: '.75rem',
    [theme.breakpoints.up('md')]: {
      height: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  tableActionDownload: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.dark
    },
    '& img': {
      height: '20px',
      marginLeft: 4
    }
  },
  visibilityHidden: {
    visibility: 'hidden'
  },
  customCheckbox: {
    width: '16px',
    height: '16px',
    border: '1px solid #54C278',
    backgroundClip: 'content-box',
    padding: '1px'
  },
  greenBackgroundColor: {
    backgroundColor: '#54C278'
  },
  managementForm: {
    [theme.breakpoints.up('sm')]: {
      width: '573px',
      justifyContent: 'flex-start'
    },
    [theme.breakpoints.up('md')]: {
      //! 960 === sm => md
      width: 'auto'
    }
  }
}));
