import React, { useEffect, useState } from 'react';
import AuthSignIn, { IAuthSignIn } from './AuthSignIn';
import AuthSignedIn, { IAuthSignedIn } from './AuthSignedIn';
import { auth } from '../../services';

export interface IAuthMenu extends IAuthSignIn, IAuthSignedIn {}

const AuthMenu: React.FC<IAuthMenu> = ({ onSignIn, onSignOut }) => {
  const [user, setUser] = useState(auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => setUser(user));
    return () => unsubscribe();
  }, []);

  return user ? (
    <AuthSignedIn onSignOut={onSignOut} />
  ) : (
    <AuthSignIn onSignIn={onSignIn} type="appbar" />
  );
};

export { AuthMenu as default, AuthMenu };
