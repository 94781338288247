import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  AppBar,
  Grid,
  makeStyles,
  IconButton,
  Button,
  Link,
  useMediaQuery,
  useTheme,
  MenuItem,
  Menu
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher, AuthDialog, AuthMenu } from '../..';
import { useCommonStyles } from '../../../css';
import { auth } from 'services';
import { checkIsAdmin } from '../../../services/db';
import { checkIsWriter } from '../../../services/db';
import { checkTookFreeTrial } from 'services/db/checkTookFreeTrial';

const useStyles = makeStyles(theme => ({
  appbar: {
    width: '100vw',
    position: 'sticky',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 3),
    textAlign: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10) - 1,
      padding: theme.spacing(1, 3),
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  appbarNoBoxShadow: {
    boxShadow: 'none'
  },
  heightViewport: { height: '100vh' },
  appbarCommand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flex: 0.5,
      textAlign: 'left'
    }
  },
  appbarLogo: {
    '& > img': {
      height: 31
    },
    position: 'relative',
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      flex: '0.9'
    }
  },
  appbarDrawer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'inherit',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  hide: {
    display: 'none'
  },
  appbarLinks: {
    justifyContent: 'space-evenly',
    flexDirection: 'inherit',
    flex: '1',
    maxWidth: 1000,
    marginLeft: 'auto'
  },
  appBarLink: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  appbarButtonsContainer: {
    justifyContent: 'space-between',
    flexDirection: 'inherit',
    flex: 1,
    placeContent: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flex: 0.6
    },
    [theme.breakpoints.up('md')]: {
      flex: 0.5
    },
    [theme.breakpoints.up('lg')]: {
      flex: 0.4
    },
    [theme.breakpoints.up(1500)]: {
      flex: 0.3
    }
  },
  appBarTrialButton: {
    order: 0,
    height: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.dark },
    width: `calc(100% + ${theme.spacing(6)}px)`,
    marginLeft: -theme.spacing(3),
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    boxShadow: '5px -2px 30px rgba(84, 194, 120, 0.57)',
    borderRadius: 0,
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 15,
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
      textAlign: 'center',
      verticalAlign: 'middle',
      boxShadow: '5px -2px 30px rgba(84, 194, 120, 0.57)',
      borderRadius: 0,
      width: 133,
      color: 'white',
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: 15
    }
  },
  appbarAccountButton: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: 12,
    height: 128.45806884765625,
    width: 296,

    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',

    '& .MuiButton-label': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    margin: 'auto auto auto auto',
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
      '& .MuiButton-label span': {
        display: 'none'
      },
      height: 39,
      width: 39,
      border: 'none',
      minWidth: 'unset',
      borderRadius: '50%',
      marginLeft: 'unset',
      marginRight: 'unset'
    }
  },
  appbarLanguageButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    order: 2,
    display: 'inherit',
    [theme.breakpoints.up('md')]: { order: 1 }
  },
  size39: { width: 39, height: 39 }
}));

export interface IAppbar {
  showFreeTrialButton: boolean;
}

export const Appbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrollTop, setIsScrollTop] = useState(window.scrollY === 0);
  const [docAnchor, setDocAnchor] = useState<null | HTMLElement>(null);
  const [adminAnchor, setAdminAnchor] = useState<null | HTMLElement>(null);
  const [productAnchor, setProductAnchor] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('common');

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const theme = useTheme();
  const breakpointUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const [isAdmin, setIsAdmin] = useState(false);
  const [isWriter, setIsWriter] = useState(false);
  const [tookFreeTrial, setTookFreeTrial] = useState(true);
  const history = useHistory();

  const handleDocOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setDocAnchor(e.currentTarget);
  };
  const handleDocClose = () => {
    setDocAnchor(null);
  };
  const handleAdminOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAdminAnchor(e.currentTarget);
  };
  const handleAdminClose = () => {
    setAdminAnchor(null);
  };
  const handleProductOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    setProductAnchor(e.currentTarget);
  };
  const handleProductClose = () => {
    setProductAnchor(null);
  };

  useEffect(() => {
    const uid: string = auth.currentUser?.uid || '';
    if (!uid) {
      return;
    }
    //we check in the database in the collection admins if the user is an admin
    const unsubscribe = checkIsAdmin(uid, setIsAdmin);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const uid: string = auth.currentUser?.uid || '';
    if (!uid) {
      return;
    }
    //we check in the database in the collection admins if the user is an admin
    const unsubscribe = checkIsWriter(uid, setIsWriter);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const uid: string = auth.currentUser?.uid || '';
    if (!uid) {
      console.log('not authentified');
      return;
    }
    //we check in the database in the collection admins if the user has took a license
    const unsubscribe = checkTookFreeTrial(uid, setTookFreeTrial);
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isOpen && breakpointUpMd) {
      setIsOpen(false);
    }
  }, [breakpointUpMd, isOpen]);

  // No box shadow on top of the page
  useEffect(() => {
    const handleScroll = () => setIsScrollTop(window.scrollY === 0);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [setIsScrollTop]);

  // Disable scroll on body when the appbar is open in mobile
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      // document.body.style.position = "fixed";
    } else {
      document.body.style.removeProperty('overflow-y');
      // document.body.style.removeProperty("position");
    }
  }, [isOpen]);

  // props
  const pageLinks = [
    {
      label: t('Home'),
      link: '/'
    },
    {
      label: t('Products'),
      items: [
        {
          text: 'Solvers',
          doclink: '/products/solvers'
        },
        {
          text: 'Graphs',
          doclink: '/products/graphs'
        }
      ]
    },
    {
      label: t('Downloads'),
      link: '/downloads'
    },
    {
      label: t('Pricing'),
      link: '/pricing'
    },
    {
      label: 'Documentation',
      items: [
        {
          text: 'Solvers',
          doclink: '/doc/solvers'
        },
        {
          text: 'Graphs',
          doclink: '/doc/graphs'
        }
      ]
    },
    {
      label: t('Contact'),
      link: '/contact'
    }
  ];

  if (isAdmin) {
    pageLinks.push({
      label: 'Admin',
      items: [
        {
          text: 'Management',
          doclink: '/admin-management'
        },
        {
          text: 'Team Plans',
          doclink: '/team-plans'
        },
        {
          text: 'Individual Plans',
          doclink: '/individual-plans'
        },
        {
          text: 'Articles',
          doclink: '/admin-articles'
        }
      ]
    });
  }

  if (isWriter) {
    pageLinks.push({
      label: 'Admin',
      items: [
        {
          text: 'Articles',
          doclink: '/admin-articles'
        }
      ]
    });
  }

  return (
    <AppBar
      className={clsx(
        classes.appbar,
        isScrollTop && classes.appbarNoBoxShadow,
        isOpen && !breakpointUpMd && classes.heightViewport
      )}>
      <div className={classes.appbarCommand}>
        <Link to={'/'} className={classes.appbarLogo} component={RouterLink}>
          <img
            src="/images/logo/logo-large.svg"
            alt="QuickCells logo"
            className="max-w-none"
          />
        </Link>
        <Grid
          item
          className={clsx(breakpointUpMd && classes.hide, classes.size39)}
          component={IconButton}
          onClick={() => setIsOpen(!isOpen)}>
          <img src="/images/naviguation-icons/menu.svg" alt="Menu Icon" />
        </Grid>
      </div>

      <div
        className={
          clsx(
            classes.appbarDrawer,
            !isOpen && !breakpointUpMd && classes.hide
          ) + ' mx-auto'
        }>
        <Grid container className={classes.appbarLinks}>
          {pageLinks.map(({ label, link, items }, i) => {
            return (
              <Grid item key={i} className="">
                <Link
                  to={link ? link : '#'}
                  component={RouterLink}
                  className={classes.appBarLink}
                  onClick={() => setIsOpen(Boolean(items))}>
                  {!items ? (
                    <p className="font-bold font-roboto">{label}</p>
                  ) : (
                    <React.Fragment>
                      <span
                        onClick={
                          label === 'Admin'
                            ? handleAdminOpen
                            : label === t('Products')
                            ? handleProductOpen
                            : handleDocOpen
                        }
                        aria-controls="simple-menu"
                        aria-haspopup="true">
                        <div className="flex flex-row items-center">
                          <p className="flex flex-row items-center font-bold font-roboto mx-auto">
                            {label}
                            <div className="xl:ml-2 ml-2">
                              <svg
                                width="10"
                                height="5"
                                viewBox="0 0 17 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M0.296062 1.85197C-0.0986874 1.4382 -0.0986874 0.767354 0.296062 0.353586C0.690812 -0.0601813 1.33083 -0.0601813 1.72558 0.353586L9.20304 8.19129C9.59779 8.60506 9.59779 9.27591 9.20304 9.68967C8.80829 10.1034 8.16827 10.1034 7.77352 9.68967L0.296062 1.85197Z"
                                  fill="#000000"
                                />
                                <path
                                  d="M9.22648 9.64641C8.83173 10.0602 8.19171 10.0602 7.79696 9.64641C7.40221 9.23265 7.40221 8.5618 7.79696 8.14803L15.2744 0.310326C15.6692 -0.103442 16.3092 -0.103442 16.7039 0.310325C17.0987 0.724093 17.0987 1.39494 16.7039 1.80871L9.22648 9.64641Z"
                                  fill="#000000"
                                />
                              </svg>
                            </div>
                          </p>
                        </div>
                      </span>
                      <Menu
                        disableScrollLock
                        anchorEl={
                          label === 'Admin'
                            ? adminAnchor
                            : label === t('Products')
                            ? productAnchor
                            : docAnchor
                        }
                        open={Boolean(
                          label === 'Admin'
                            ? adminAnchor
                            : label === t('Products')
                            ? productAnchor
                            : docAnchor
                        )}
                        onClose={
                          label === 'Admin'
                            ? handleAdminClose
                            : label === t('Products')
                            ? handleProductClose
                            : handleDocClose
                        }
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: -60,
                          horizontal: 'left'
                        }}>
                        {items.map(
                          ({ text, doclink }, idx) =>
                            text && (
                              <MenuItem
                                onClick={
                                  label === 'Admin'
                                    ? handleAdminClose
                                    : handleDocClose
                                }
                                key={`${i}-${idx}`}>
                                <Link
                                  to={doclink}
                                  component={RouterLink}
                                  onClick={event => {
                                    //prevent default
                                    event.preventDefault();
                                    history.push(doclink);
                                    setIsOpen(false);
                                    //force update
                                    window.location.reload();
                                  }}>
                                  {label !== 'Admin' && (
                                    <div className="flex flex-row">
                                      <img
                                        src={`/images/icons/${text.toLowerCase()}.png`}
                                        alt={`${text} Icon`}
                                        className="mr-2"
                                      />
                                      <div className="flex flex-col">
                                        <div className="flex flex-row">
                                          <p className="font-roboto text-sm">
                                            QuickCells{' '}
                                            <span>{text.toUpperCase()}</span>
                                          </p>
                                        </div>
                                        <p className="font-roboto text-xs">
                                          {text === 'Solvers'
                                            ? label === t('Products')
                                              ? t('solversProductTab')
                                              : t('solversDocTab')
                                            : label === t('Products')
                                            ? t('graphsProductTab')
                                            : t('graphsDocTab')}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                  {label === 'Admin' && <p>{text}</p>}
                                </Link>
                              </MenuItem>
                            )
                        )}
                      </Menu>
                    </React.Fragment>
                  )}
                </Link>
              </Grid>
            );
          })}
        </Grid>

        <Grid container className={classes.appbarButtonsContainer}>
          <Grid item>
            <Button
              component={RouterLink}
              className={clsx(
                classes.appBarTrialButton,
                commonClasses.backgroundPrimary
              )}
              to={
                auth.currentUser?.uid
                  ? '/checkout/PREMIUM_FREE_TRIAL'
                  : '/login'
              }
              onClick={() => setIsOpen(false)}>
              {t('free trial')}
            </Button>
            {!breakpointUpMd && <AuthMenu />}
          </Grid>
          <Grid item className={classes.appbarLanguageButton}>
            <LanguageSwitcher />
          </Grid>
        </Grid>
        {breakpointUpMd && (
          <div className="mb-3">
            <AuthMenu />
          </div>
        )}
      </div>
    </AppBar>
  );
};

export default Appbar;
