import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { plansToString } from '../services/paypal';
import { ScrollToTop, ProtectedRoute } from '../components';
import { SuspenseFallback } from '../SuspenseFallback';
import ViewPlansAdmin from '../views/ViewPlansAdmin';
import SignIn from 'views/SignIn';
const Checkout = lazy(() => import('../views/Checkout/Checkout'));
const FreeTrialCheckout = lazy(() =>
  import('../views/Checkout/FreeTrialCheckout')
);
const ErrorBoundary = lazy(() => import('../views/Errors/ErrorBoundary'));
const Dashboard = lazy(() => import('../views/Dashboard/Choice'));

const Routes: React.FC = () => (
  <Router>
    <ScrollToTop>
      <Suspense fallback={<SuspenseFallback />}>
        <Switch>
          {/* DummyLinksView */}
          {(process.env.REACT_APP_QUICKCELLS_ENV === 'DEVELOPMENT' ||
            process.env.NODE_ENV === 'development') && (
            <Route
              exact
              path="/links"
              component={lazy(() => import('../views/DummyLinksView'))}
            />
          )}
          {/* Home */}
          <Route
            exact
            path="/"
            component={lazy(() => import('../views/Home/Home'))}
          />
          {/* Products */}
          <Route
            exact
            path="/products/graphs"
            component={lazy(() => import('../views/Products/ProductGraphs'))}
          />
          <Route
            exact
            path="/products/solvers"
            component={lazy(() => import('../views/Products/ProductSolvers'))}
          />
          <Redirect from="/product/quickcells-graphs" to="/products/graphs" />
          <Redirect from="/product/quickcells-solvers" to="/products/solvers" />
          {/* Documentation*/}
          <Route
            exact
            path="/downloads"
            component={lazy(() => import('../views/Doc/General/General'))}
          />
          <Route
            exact
            path="/doc/graphs"
            component={lazy(() => import('../views/Doc/Graphs/Graphs'))}
          />
          <Route
            exact
            path="/doc/solvers"
            component={lazy(() => import('../views/Doc/Solvers/Solvers'))}
          />
          {/* Pricing */}
          <Route
            exact
            path="/pricing"
            component={lazy(() => import('../views/Pricing/PricingPackages'))}
          />
          <Route
            exact
            path="/pricing/trial"
            component={lazy(() => import('../views/Pricing/PricingTrial'))}
          />
          <Route
            exact
            path="/pricing/quote"
            component={lazy(() => import('../views/Pricing/PricingQuote'))}
          />
          <Route
            exact
            path="/pricing/:product(solvers|graphs|premium)"
            component={lazy(() => import('../views/Pricing/PricingChoice'))}
          />
          <Route
            exact
            path="/pricing/:product(solvers|graphs|premium)/:userType(team|individual)"
            component={lazy(() => import('../views/Pricing/PricingLicences'))}
          />
          {/* Legal */}
          <Route
            exact
            path="/legal/tos"
            component={lazy(() => import('../views/Legal/TermsOfService'))}
          />
          <Route
            exact
            path="/legal/privacy"
            component={lazy(() => import('../views/Legal/PrivacyPolicy'))}
          />
          <Route
            exact
            path="/legal/dpa"
            component={lazy(() =>
              import('../views/Legal/DataProcessingAdendum')
            )}
          />
          {/* Contact */}
          <Route
            exact
            path="/contact"
            component={lazy(() => import('../views/Contact'))}
          />

          {/* Register */}
          <Route exact path="/register">
            <SignIn typeSignIn={false} />
          </Route>

          {/* Login */}
          <Route exact path="/login">
            <SignIn typeSignIn={true} />
          </Route>

          {/* Dashboard (Protected + Error Boundary) */}
          <ProtectedRoute exact path="/dashboard">
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </ProtectedRoute>

          {/* Checkout (Protected + Error Boundary) */}
          <ProtectedRoute
            exact
            path={`/checkout/:subscriptionPlanName(${plansToString(
              'paying'
            )})/:licensesNb?`}>
            <ErrorBoundary>
              <Checkout />
            </ErrorBoundary>
          </ProtectedRoute>

          {/* Admin */}
          <ProtectedRoute
            exact
            path="/admin-management"
            component={lazy(() => import('../views/AdminManagement'))}
          />
          {/* Professional plans */}
          <ProtectedRoute exact path="/team-plans">
            <ViewPlansAdmin type="team" />
          </ProtectedRoute>

          {/* Individual plans */}
          <ProtectedRoute exact path="/individual-plans">
            <ViewPlansAdmin type="individual" />
          </ProtectedRoute>

          {/* Checkout for Free Plans (Protected + Error Boundary) */}
          <ProtectedRoute
            exact
            path={`/checkout/:subscriptionPlanName(${plansToString('free')})`}>
            <ErrorBoundary>
              <FreeTrialCheckout />
            </ErrorBoundary>
          </ProtectedRoute>
          {/* Errors */}
          <Route
            exact
            path="*"
            component={lazy(() => import('../views/Errors/Errors'))}
          />
        </Switch>
      </Suspense>
    </ScrollToTop>
  </Router>
);

export { Routes };
