import React, { useState, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { auth } from '../../services';

export const ProtectedRouteProgress: React.FC<RouteProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let isMounted = true;
    auth.waitUserLoad().then(() => {
      if (isMounted) setIsLoading(false);
    });

    // Increment progress bar
    const timer = setInterval(
      () =>
        setProgress((oldProgress) =>
          oldProgress + 1 > 100 ? 100 : oldProgress + 1
        ),
      5
    );

    return () => {
      isMounted = false;
      clearInterval(timer);
    };
  }, []);

  return isLoading ? (
    <Backdrop open={true}>
      <CircularProgress variant="determinate" value={progress} />
    </Backdrop>
  ) : auth.isAuthenticated ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={`/auth${
        props.location?.pathname &&
        '?redirect=' +
          encodeURIComponent(props.location.pathname + props.location.search)
      }`}
    />
  );
};

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);

  useEffect(() => {
    return auth.onAuthStateChanged(() => {
      setIsAuthenticated(auth.isAuthenticated);
    });
  }, [setIsAuthenticated]);

  if (isAuthenticated) {
    return <Route {...props} />;
  } else {
    return <ProtectedRouteProgress {...props} />;
  }
};

export default ProtectedRoute;
