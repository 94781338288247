import { database } from '../database';
import { ILicenseType } from './types';

// TODO: Mettre des trycatch
//! TODO: SUPPRIMER (plus appelé nulle part)
export async function updateLicenceType(
  companyId: string,
  counterType: ILicenseType,
  soft: string
) {
  const unusedLicenses = await database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('status', '==', 'active')
    .where('type', '==', 'server')
    //we look for license with the same soft or with the soft premium
    .where('soft', 'in', [soft, 'premium'])
    .get();

  if (!unusedLicenses.empty) {
    const licenseId = unusedLicenses.docs[0].id;
    const updates: { status?: 'cancelled'; type?: 'server' } = {};

    if (counterType === 'server') updates.type = 'server';

    await database()
      .collection('licenses')
      .doc(licenseId)
      .update(updates);
    /*
    if (updates.status == 'cancelled') {
      //we cancel the subscription associated to the license
      var subscription = unusedLicenses.docs[0].data().subscription;
      if (subscription) {
        await database()
          .collection('subscriptions')
          .doc(subscription)
          .update({ status: 'pending_suspension' });
      }
    }
    */
  }
}
