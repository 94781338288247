import { database } from '..';
import { ISubscription } from './types';

// DONE: généraliser le license Key
export interface WithKey<T> {
  key: string;
  data: T;
}
export type SubWithKey = WithKey<ISubscription>;

//TODO: Faire une fonction snapSubs et export composés: snapCompanySubs et snapIndividualSubs et snapCollaboratorSubs du coup (ce sera plus Licenses que Subs))

export function snapCompanySubs(companyId: string, update: Function): Function {
  const unsubscribeMethod: Function = database()
    .collection('subscriptions')
    .where('owner', '==', companyId)
    .where('status', '!=', 'cancelled')
    .orderBy('status', 'asc')
    .orderBy('duration.expiresIn', 'asc')
    .onSnapshot(
      querySnapshot => {
        const snapSubs: WithKey<ISubscription>[] = [];
        querySnapshot.forEach(doc => {
          if (doc.exists) {
            snapSubs.push({
              data: doc.data() as ISubscription,
              key: doc.id
            });
          }
        });
        update(snapSubs);
      },
      error => console.log(error)
    );

  return unsubscribeMethod;
}
