import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonProps,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';

type IElementSize = {
  width: number;
  height: number;
};

const useStyles = makeStyles(() => ({
  buttonLabelWidth: ({
    buttonLabelSize
  }: {
    buttonLabelSize: IElementSize | undefined;
  }) => ({
    ...(buttonLabelSize && {
      width: buttonLabelSize.width,
      height: buttonLabelSize.height
    })
  }),
  verticalAlignMiddle: {
    verticalAlign: 'middle'
  },
  colorWhite: { color: 'white' }
}));

interface ILoadingButton extends ButtonProps {
  isLoading: boolean;
  isRendered?: boolean;
  whiteProgress?: boolean;
}

const LoadingButton: React.FC<ILoadingButton> = ({
  children,
  isLoading,
  whiteProgress,
  onClick,
  ...buttonProps
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  const [buttonLabelSize, setButtonLabelSize] = useState<IElementSize>();
  const classes = useStyles({ buttonLabelSize });

  const manageSize = () => {
    const buttonLabel = ref.current?.querySelector('.MuiButton-label');
    if (!isLoading && buttonLabel) {
      setButtonLabelSize({
        width: buttonLabel.clientWidth,
        height: buttonLabel.clientHeight
      });
    }
  };

  useEffect(() => {
    const buttonLabel = ref.current?.querySelector('.MuiButton-label');
    if (!isLoading && buttonLabel) {
      setButtonLabelSize({
        width: buttonLabel.clientWidth,
        height: buttonLabel.clientHeight
      });
    }
  }, [isLoading]);

  return (
    <Button
      disabled={isLoading}
      {...buttonProps}
      ref={ref}
      onClick={ev => {
        manageSize();
        if (onClick) {
          onClick(ev);
        }
      }}>
      {isLoading ? (
        <span className={classes.buttonLabelWidth}>
          <CircularProgress
            size={Math.min(24, buttonLabelSize?.height || 1e9)}
            className={clsx(
              classes.verticalAlignMiddle,
              whiteProgress && classes.colorWhite
            )}
          />
        </span>
      ) : (
        children
      )}
    </Button>
  );
};

export { LoadingButton };
