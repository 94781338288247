import { database } from '../database';
import { ICompany, ILicense, ILicenseProduct, ILicenseType } from './types';

export const fetchCompanyAdmins = (
  companyId: string,
  updateList: Function
): Function =>
  database()
    .collection('companies')
    .doc(companyId)
    .onSnapshot(snapshot => {
      const company: ICompany = snapshot.data() as ICompany;
      if (typeof updateList === 'function')
        updateList(company.admins.map(admin => ({ email: admin })));
    });

interface ICollaboratorsList {
  email: string;
  licenseId: string;
  type?: ILicenseType;
  soft: ILicenseProduct;
}

export const fetchCompanyCollaborators = (
  companyId: string,
  updateList: Function
) =>
  database()
    .collection('licenses')
    .where('context', '==', 'team')
    .where('owner', '==', companyId)
    .where('status', '==', 'active')
    .onSnapshot(snapshots => {
      let updatedList: ICollaboratorsList[] = [];
      snapshots.forEach(snap => {
        if (snap.exists) {
          const { heirs, soft, type } = snap.data() as ILicense;
          updatedList = updatedList.concat(
            heirs.map(heir => ({
              type,
              soft,
              email: heir,
              licenseId: snap.id
            }))
          );
        }
      });
      updateList(updatedList);
    });
