import { database } from '..';
import { ILicense } from './types';

interface ILicenseKey {
  license: ILicense;
  key: string;
}

//! TODO: Supprimer (inutile - remplacé par snapCompanySubs)

function snapIndividualLicenses(
  email: string,
  setLicenses: Function
): Function {
  const availableStatus: string[] = [
    'active',
    'pending_activation',
    'pending_suspension',
    'suspended',
    'cancelled'
  ];
  const unsubscribeMethod: Function = database()
    .collection('licenses')
    .where('status', 'in', availableStatus)
    .where('heirs', 'array-contains', email)
    .orderBy('startedAt', 'desc')
    .onSnapshot(
      (querySnapshot) => {
        const snapLicenses: ILicenseKey[] = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            snapLicenses.push({
              license: doc.data() as ILicense,
              key: doc.id
            });
          }
        });
        setLicenses(snapLicenses);
      },
      (error) => console.log(error)
    );

  return unsubscribeMethod;
}

export { snapIndividualLicenses as default, snapIndividualLicenses };
