import { database } from '..';

export const checkIsAdmin = (
  uid: string,
  updateIsAdmin: Function
): Function => {
  //we check if there is one document in the admins collection which id is the uid of the user
  //function take uid as parameter
  if (!uid) uid = '';
  const unsubscriber: Function = database()
    .collection('administrators')
    .doc(uid)
    .onSnapshot(doc => {
      if (doc.exists) {
        updateIsAdmin(true);
      } else {
        updateIsAdmin(false);
      }
    });
  return unsubscriber;
};
