import { database } from '..';
import { ISubscription, ILicense } from './types';

// DONE: généraliser le license Key
export interface WithKey<T> {
  key: string;
  data: T;
}
export type SubWithKey = WithKey<ISubscription>;

export function snapCollaboratorSubs(
  email: string,
  update: Function
): Function {
  const unsubscribeMethod: Function = database()
    .collection('licenses')
    .where('status', '==', 'active')
    .where('heirs', 'array-contains', email)
    .onSnapshot(
      async (querySnapshot) => {
        const subIds: string[] = [];
        querySnapshot.forEach((doc) =>
          subIds.push((doc.data() as ILicense).subscription)
        );

        const snapSubs: Array<WithKey<ISubscription> | null> =
          await Promise.all(
            subIds.map(async (subId) => {
              const req = await database()
                .collection('subscriptions')
                .doc(subId)
                .get();
              if (req.exists) {
                return {
                  key: subId,
                  data: req.data() as ISubscription
                };
              } else return null;
            })
          );
        update(snapSubs.filter((sub) => sub !== null));
      },
      (error) => console.log(error)
    );

  return unsubscribeMethod;
}
