import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import * as translationEN from './en';
import * as translationFR from './fr';
import DocumentationFR from './fr/documentation.json';
import DocumentationEN from './en/documentation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: { ...translationEN, DocumentationEN },
      fr: { ...translationFR, DocumentationFR }
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: [
      'checkout',
      'common',
      'dashboard',
      'documentation',
      'solverdoc',
      'errors',
      'forms',
      'home',
      'legal',
      'pricing',
      'products'
    ],
    defaultNS: 'common',

    // keySeparator: false, // we use content as keys
    returnObjects: true,
    interpolation: {
      escapeValue: false
    }
  });
