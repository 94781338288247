import React, { useEffect, useState } from 'react';
import AuthSignIn, { IAuthSignIn } from '../components/auth/AuthSignIn';
import { auth } from '../services';
import { sendMailSendinblue } from '../services/callableFunctions';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { Link, useHistory } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import {
  AppBar,
  Grid,
  makeStyles,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  MenuItem,
  Menu
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  appbar: {
    width: '100vw',
    position: 'sticky',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 3),
    textAlign: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.09)',
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10) - 1,
      padding: theme.spacing(1, 3),
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  appbarNoBoxShadow: {
    boxShadow: 'none'
  },
  heightViewport: { height: '100vh' },
  appbarCommand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flex: 0.5,
      textAlign: 'left'
    }
  },
  appbarLogo: {
    '& > img': {
      height: 31
    },
    position: 'relative',
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      flex: '0.9'
    }
  },
  appbarDrawer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'inherit',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      alignItems: 'center'
    }
  },
  hide: {
    display: 'none'
  },
  appbarLinks: {
    justifyContent: 'space-evenly',
    flexDirection: 'inherit',
    flex: '1',
    maxWidth: 1000,
    marginLeft: 'auto'
  },
  appBarLink: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    }
  },
  appbarButtonsContainer: {
    justifyContent: 'space-between',
    flexDirection: 'inherit',
    flex: 1,
    placeContent: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flex: 0.6
    },
    [theme.breakpoints.up('md')]: {
      flex: 0.5
    },
    [theme.breakpoints.up('lg')]: {
      flex: 0.4
    },
    [theme.breakpoints.up(1500)]: {
      flex: 0.3
    }
  },
  appBarTrialButton: {
    order: 0,
    height: theme.spacing(10),
    backgroundColor: theme.palette.primary.main,
    '&:hover': { backgroundColor: theme.palette.primary.dark },
    width: `calc(100% + ${theme.spacing(6)}px)`,
    marginLeft: -theme.spacing(3),
    textAlign: 'center',
    verticalAlign: 'middle',
    display: 'flex',
    boxShadow: '5px -2px 30px rgba(84, 194, 120, 0.57)',
    borderRadius: 0,
    color: 'white',
    textTransform: 'capitalize',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 15,
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
      textAlign: 'center',
      verticalAlign: 'middle',
      boxShadow: '5px -2px 30px rgba(84, 194, 120, 0.57)',
      borderRadius: 0,
      width: 133,
      color: 'white',
      textTransform: 'capitalize',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      fontSize: 15
    }
  },
  appbarAccountButton: {
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: 12,
    height: 128.45806884765625,
    width: 296,

    fontWeight: 'bold',
    fontSize: 20,
    textAlign: 'center',

    '& .MuiButton-label': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly'
    },
    margin: 'auto auto auto auto',
    order: 1,
    [theme.breakpoints.up('md')]: {
      order: 2,
      '& .MuiButton-label span': {
        display: 'none'
      },
      height: 39,
      width: 39,
      border: 'none',
      minWidth: 'unset',
      borderRadius: '50%',
      marginLeft: 'unset',
      marginRight: 'unset'
    }
  },
  appbarLanguageButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    order: 2,
    display: 'inherit',
    [theme.breakpoints.up('md')]: { order: 1 }
  },
  size39: { width: 39, height: 39 }
}));

const SignIn: React.FC<{ typeSignIn: boolean }> = ({ typeSignIn }) => {
  const [user, setUser] = useState(auth.currentUser);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signIn, setSignIn] = useState(typeSignIn);
  const [forgotPassword, setForgotPassword] = useState(false);
  const history = useHistory();
  const classes = useStyles();

  const { t } = useTranslation('dashboard');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => setUser(user));
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user) {
      history.push('/dashboard');
    }
  }, [user]);

  const auth_firebase = getAuth();

  const signInUp = (email: string, password: string) => {
    if (!signIn) {
      if (password.length < 7) {
        alert(t('contains'));
        setPassword('');
        return;
      }
      createUserWithEmailAndPassword(auth_firebase, email, password)
        .then(userCredential => {
          console.log(userCredential);
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(t('already_used'));
        });
    } else {
      signInWithEmailAndPassword(auth_firebase, email, password)
        .then(userCredential => {
          console.log(userCredential);
        })
        .catch(error => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(t('incorrect'));
        });
    }
  };
  if (!forgotPassword) {
    return (
      <div className="flex flex-col">
        <Link to={'/'} className={classes.appbarLogo + ' p-6'}>
          <img
            src="/images/logo/logo-large.svg"
            alt="QuickCells logo"
            className="max-w-none"
          />
        </Link>
        <div className="flex flex-col mx-auto mt-40 text-2xl">
          <div className="flex flex-col mx-auto">
            {!signIn ? (
              <div className="flex flex-col mx-auto">
                <div className="flex flex-row mx-auto">
                  <p>{t('start_quick')}</p>
                  <p className="text-green ml-2">{t('free')}</p>
                  <p className="ml-2">!</p>
                </div>
                <div className="mx-auto flex flex-col">
                  <p className="font-roboto font-normal	text-xs leading-4 text-grey text-center">
                    {t('freeTrial')}
                  </p>
                  <p className="font-roboto font-normal	text-xs leading-4 text-grey text-center">
                    {t('noCreditCard')}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex flex-row mx-auto">
                <p>{t('loginto')}</p>
                <p className="text-green ml-2">{t('account')}</p>
                <p className="ml-2">!</p>
              </div>
            )}
          </div>
          <AuthSignIn></AuthSignIn>
          <p className="font-roboto mx-auto mt-3">or</p>
          <input
            className="px-6 py-2 flex flex-row rounded-full border border-grey-light mx-auto w-96 mt-6 text-xl h-14 items-center"
            placeholder="Email address"
            onChange={e => setEmail(e.target.value)}
            value={email}
          />
          <input
            className="px-6 py-2 flex flex-row rounded-full border border-grey-light mx-auto w-96 mt-6 text-xl h-14 items-center"
            placeholder="Password"
            onChange={e => {
              setPassword(e.target.value);
            }}
            value={password}
            type="password"
          />
          {!signIn && (
            <div className="flex flex-col mt-4 mx-auto">
              <p className="font-roboto text-xs">{t('minimum')}</p>
            </div>
          )}
          <button
            className="bg-green px-6 py-2 flex flex-row rounded-full border border-grey-light mx-auto w-96 mt-6 text-xl h-14 items-center"
            onClick={() => {
              signInUp(email, password);
            }}>
            <p className="text-white font-bold font-roboto mx-auto">
              {signIn ? t('login') : t('signup')}
            </p>
          </button>
          {!signIn && (
            <p className="font-roboto text-xs mx-auto w-80 mt-3">
              {t('agree')}
            </p>
          )}
          <div className="flex flex-row mt-3">
            <button
              onClick={() => {
                setSignIn(!signIn);
              }}>
              <div className="font-roboto text-xs mt-2 ml-4">
                {!signIn ? (
                  <div className="flex flex-row ml-4">
                    <p>{t('have_account')}</p>
                    <p className="underline ml-2">{t('signin')}</p>
                  </div>
                ) : (
                  <p className="underline">{t('dont_have_account')}</p>
                )}
              </div>
            </button>
            {signIn && (
              <button
                className="ml-auto mt-2 mr-4"
                onClick={() => setForgotPassword(true)}>
                <p className="font-roboto underline text-xs">{t('forgot')}</p>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <ResetPassword />;
  }
};

export { SignIn as default, SignIn };
