import { database, auth } from '..';

// TODO: Ajouter un trycatch

const manageUserDeletion = async (): Promise<void> => {
  //if user is admin of a company, delete the company
  const companies = await database()
    .collection('companies')
    //where admin contains
    .where('admins', 'array-contains', auth.currentUser?.email)
    .get();

  if (!companies.empty) {
    const company_id = companies.docs[0].id;
    //we cancel all the licenses and the subscription associated to the company
    const licenses = await database()
      .collection('licenses')
      .where('owner', '==', company_id)
      .where('status', '==', 'active')
      .get();

    if (!licenses.empty) {
      // await for each license to be cancelled
      await Promise.all(
        licenses.docs.map(async doc => {
          await database()
            .collection('licenses')
            .doc(doc.id)
            .update({ status: 'cancelled' });
          //we change the heirs array of the license to an empty array
          await database()
            .collection('licenses')
            .doc(doc.id)
            .update({ heirs: [] });
          //we cancel the subscription associated to the license
          const subscription = await database()
            .collection('subscriptions')
            .doc(licenses.docs[0].data().subscription)
            .get();

          if (subscription.exists) {
            await database()
              .collection('subscriptions')
              .doc(subscription.id)
              .update({ status: 'cancelled' });
          }
        })
      );
    }
    await database()
      .collection('companies')
      .doc(company_id)
      .delete();

    return;
  }

  const licenses = await database()
    .collection('licenses')
    .where('context', '==', 'individual')
    .where('owner', '==', auth.currentUser?.uid)
    .where('status', '==', 'active')
    .get();

  if (!licenses.empty) {
    licenses.docs.forEach(async doc => {
      await database()
        .collection('licenses')
        .doc(doc.id)
        .update({ status: 'cancelled' });

      //update subscription associated to the license
      const subscription = await database()
        .collection('subscriptions')
        .doc(doc.data().subscription)
        .get();
      console.log('subscription', subscription);

      if (subscription.exists) {
        await database()
          .collection('subscriptions')
          .doc(subscription.id)
          .update({ status: 'cancelled' });
      }
    });
  }

  //delete the user from firebase auth
  await auth.currentUser?.delete();
  console.log('user deleted from firebase auth');
};

export { manageUserDeletion as default, manageUserDeletion };
