import React, { useEffect, useState } from 'react';
import { Snackbar, Fade, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  success: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.primary.main
    }
  },
  error: {
    '& .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.error.main
    }
  }
}));

interface SnackBarProps {
  success?: boolean;
  successMessage?: string;
  errorMessage?: string;
  resetSuccess: () => void;
}
const SnackBar: React.FC<SnackBarProps> = ({
  success,
  resetSuccess,
  successMessage,
  errorMessage
}) => {
  const classes = useStyles();
  const [message, setMessage] = useState<string>('');
  const { t } = useTranslation();

  useEffect(() => {
    if (success === undefined) setMessage('');
    else
      setMessage(
        success
          ? successMessage || t('common:success')
          : errorMessage || t('common:something went wrong')
      );
  }, [errorMessage, success, successMessage, t]);

  return (
    <Snackbar
      className={clsx({
        [classes.success]: success,
        [classes.error]: !success
      })}
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      message={message}
      open={success !== undefined}
      autoHideDuration={6000}
      onClose={resetSuccess}
    />
  );
};

export { SnackBar };
