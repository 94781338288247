import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, Backdrop } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: 10000,
    backgroundColor: 'rgb(0 0 0 / 90%)'
  },
  imgFullScreen: {
    width: '90%',
    height: '90%',
    objectFit: 'contain'
  },
  cursorZoomIn: {
    cursor: 'zoom-in'
  }
}));

export const ImgZoom: React.FC<{
  src: string;
  alt: string;
  className?: string;
  width?: string;
  height?: string;
  srcBackdrop?: string;
}> = ({ src, alt, className, width, height, srcBackdrop }) => {
  const ref = useRef<HTMLImageElement>(null);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflowY = isOpen ? 'hidden' : 'unset';
  }, [isOpen]);

  return (
    <React.Fragment>
      <img
        ref={ref}
        src={src}
        alt={alt}
        width={width}
        height={height}
        className={clsx(classes.cursorZoomIn, className)}
        onClick={() => setIsOpen(true)}
      />
      <Backdrop
        open={isOpen}
        onClick={() => setIsOpen(false)}
        className={clsx(classes.backdrop)}>
        <img
          src={srcBackdrop || src}
          alt={alt}
          className={classes.imgFullScreen}
          onClick={() => setIsOpen(false)}
        />
      </Backdrop>
    </React.Fragment>
  );
};
