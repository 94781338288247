import { database } from '../database';
import { ILicense } from './types';

export async function removeLicenseToCollaborator(
  targetEmail: string,
  licenseId: string
): Promise<string> {
  const licenseReq = await database()
    .collection('licenses')
    .doc(licenseId)
    .get();

  // never happens
  if (!licenseReq.exists) return '';
  else {
    const { heirs, soft, type } = licenseReq.data() as ILicense;
    const updates: any = {};

    if (type === 'named' || heirs.length === 1) {
      updates.heirs = [];
      updates.type = 'server';
    } else {
      const mailId: number = heirs.findIndex(
        (mail: string) => mail === targetEmail
      );

      if (mailId > -1) heirs.splice(mailId, 1);
      updates.heirs = heirs;
    }

    try {
      await database()
        .collection('licenses')
        .doc(licenseId)
        .update(updates);
      return soft as string;
    } catch (error) {
      throw Error("Can't update license");
    }
  }
}
