import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AnimatedBackground from './AnimatedBackground';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100vh',
    backgroundColor: fade(theme.palette.primary.main, 0.5)
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh'
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 50,
    margin: '15px 40px',
    '& > img': { height: '100%' }
  }
}));

export interface IAnimatedLayout {
  disableLogo?: boolean;
}

export const AnimatedLayout: React.FC<IAnimatedLayout> = ({
  children,
  disableLogo
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AnimatedBackground />
      {!disableLogo && (
        <Link to="/" className={classes.logo}>
          <img src="/images/logo/logo-large.svg" alt="QuickCells logo" />
        </Link>
      )}
      <div className={classes.container}>{children}</div>
    </div>
  );
};
