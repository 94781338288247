import { database } from '../database';
import { ILicense } from './types';

export async function manageSoftCollaborator(
  companyId: string,
  target: { email: string; licenseId?: string },
  soft: string
): Promise<string> {
  console.log('target', target);
  console.log('soft', soft);
  //we find is licenseId is a server license or a named license
  var log = '';

  const previousLicenseReq = await database()
    .collection('licenses')
    .doc(target.licenseId)
    .get();

  if (previousLicenseReq.exists) {
    const previousLicense: ILicense = previousLicenseReq.data() as ILicense;
    var type = previousLicense.type;
    var previous_soft = previousLicense.soft;

    const licenseReq = await database()
      .collection('licenses')
      .where('owner', '==', companyId)
      .where('soft', '==', soft)
      .where('status', '==', 'active')
      .where('type', '==', 'server')
      .get();

    if (licenseReq.docs.length > 0 && type === 'server') {
      //we delete the target email from the previous license
      if (previousLicense.heirs.length > 1) {
        console.log('we delete the target email from the previous license');
        await database()
          .collection('licenses')
          .doc(target.licenseId)
          .update({
            heirs: previousLicense.heirs.filter(heir => heir !== target.email)
          });
      } else {
        console.log('we delete the previous license');
        await database()
          .collection('licenses')
          .doc(target.licenseId)
          .update({ heirs: [], type: 'server' });
      }

      //we add the collaborator to the server license
      console.log(
        'we add the collaborator to the server license + id : ' +
          licenseReq.docs[0].id
      );
      await database()
        .collection('licenses')
        .doc(licenseReq.docs[0].id)
        .update({
          heirs: [
            ...licenseReq.docs[0]
              .data()
              .heirs.filter((heir: string) => heir !== target.email),
            target.email
          ],
          type: 'server'
        });

      log =
        'FROM_' +
        previous_soft.toUpperCase() +
        '_TO_' +
        soft.toUpperCase() +
        '_LICENSE';
      return log;
    } else if (licenseReq.docs.length > 0 && type === 'named') {
      //we look for a server license with the same soft and no heirs
      const licenseReq = await database()
        .collection('licenses')
        .where('owner', '==', companyId)
        .where('soft', '==', soft)
        .where('status', '==', 'active')
        .where('type', '==', 'server')
        .where('heirs', '==', [])
        .get();

      if (licenseReq.docs.length > 0) {
        //we delete the target email from the previous license
        await database()
          .collection('licenses')
          .doc(target.licenseId)
          .update({
            heirs: []
          });

        await database()
          .collection('licenses')
          .doc(licenseReq.docs[0].id)
          .update({
            heirs: [target.email],
            type: 'named'
          });
      } else {
        throw new Error('No license available');
      }
    } else {
      throw new Error('No license available');
    }
  }
  return log;
}
