import {
  IBillingPlan,
  ILicenseContext,
  ILicenseProduct,
  ILicenseTimeDelay
} from '../db/types';
import BILLING_PLANS from '../../config/BILLING_PLANS.json';

const bps: IBillingPlan[] = BILLING_PLANS as IBillingPlan[];

export const listPlans = (): IBillingPlan[] => bps as IBillingPlan[];

export const getPlanByRef = (ref: string): IBillingPlan =>
  bps.find((plan: IBillingPlan) => plan.name === ref) as IBillingPlan;

export const plansToString = (kind: 'free' | 'paying'): string => {
  const getCondition = (plan: any) =>
    kind === 'free' ? plan.context === 'free' : plan.context !== 'free';

  return bps
    .filter((plan: IBillingPlan) => getCondition(plan))
    .map((plan: IBillingPlan) => plan.name)
    .join('|');
};

export const getPlan = (
  soft: ILicenseProduct,
  period: ILicenseTimeDelay,
  context: ILicenseContext
) =>
  bps.find(
    (plan) =>
      plan.soft === soft && plan.period === period && plan.context === context
  );
