import { database } from '../database';
import { ILicense } from './types';

export async function manageServerCollaborator(
  companyId: string,
  target: { email: string; licenseId?: string }
): Promise<string> {
  const maxHeirsOnServerLicense: number = 3;
  const updates: any = {};
  let log: string = '';
  const previousLicenseReq = await database()
    .collection('licenses')
    .doc(target.licenseId)
    .get();

  if (previousLicenseReq.exists) {
    const previousLicense: ILicense = previousLicenseReq.data() as ILicense;

    if (previousLicense.type === 'server') {
      const isOnlyHeirOnLicense: boolean = previousLicense.heirs.length === 1;

      // If collab is the only one heir, changes license type
      if (isOnlyHeirOnLicense) {
        updates.type = 'named';
        log = 'FROM_SERVER_TO_NAMED_LICENSE';
      } else {
        // if no Drop thecollaborator from server license and update the license to be named
        const updatedHeirs = previousLicense.heirs.filter(
          (heirEmail: string) => heirEmail === target.email
        );
        updates.heirs = updatedHeirs;
        updates.type = 'named';
        log = 'REMOVE_COLLABORATOR_FROM_SERVER_LICENSE';
      }
    } else {
      log = 'FROM_NAMED_TO_SERVER_LICENSE';

      const companyLicensesReq = await database()
        .collection('licenses')
        .where('owner', '==', companyId)
        .where('soft', '==', previousLicense.soft)
        .where('type', 'in', ['server'])
        .get();

      if (companyLicensesReq.empty) {
        updates.heirs = [target.email];
        updates.type = 'server';
      } else {
        const licenses = companyLicensesReq.docs.map(doc => ({
          id: doc.id,
          data: doc.data() as ILicense
        }));
        const serverLicenses = licenses.filter(
          license =>
            license.data.type === 'server' &&
            license.data.heirs.length <= maxHeirsOnServerLicense
        );

        /** Available server licenses => adds collaborator in one */
        if (serverLicenses.length > 0) {
          const updatedHeirs = [...serverLicenses[0].data.heirs];
          updatedHeirs.push(target.email);
          await database()
            .collection('licenses')
            .doc(serverLicenses[0].id)
            .set(
              {
                heirs: updatedHeirs
              },
              { merge: true }
            );

          /** resets collaborators previous license */
          updates.heirs = [];
          updates.type = 'server';
        } else {
          /** No server license => collaborator license updated to server license */
          updates.heirs = [target.email];
          updates.type = 'server';
        }
      }
    }
    /** Applies updates to previous collaborator license */
    await database()
      .collection('licenses')
      .doc(target.licenseId)
      .set(updates, { merge: true });
  }
  return log;
}
