import { database } from '../database';
import { ILicense } from './types';

async function fetchLicenseById(
  licenseId: string | undefined
): Promise<ILicense | undefined> {
  if (!licenseId) return undefined;

  const req = await database().collection('licenses').doc(licenseId).get();

  return req.exists ? (req.data() as ILicense) : undefined;
}

export { fetchLicenseById as default, fetchLicenseById };
