import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export interface IUserCredential extends firebase.auth.UserCredential {}

export type IOAuthProviderLabel = 'microsoft.com' | 'google.com';

class Auth {
  private _isLoaded: boolean;

  constructor() {
    // bind this for easier use (e.g in buttons : onClic={auth.signIn})
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);

    // On refresh, the user is null, even if he was logged in. We must
    // wait ~1sec to wait for the user to be logged in again
    this._isLoaded = false;
    this.waitUserLoad();
  }

  async signIn(providerLabel: IOAuthProviderLabel): Promise<IUserCredential> {
    try {
      const provider = new firebase.auth.OAuthProvider(
        providerLabel
      ).setCustomParameters({ prompt: 'select_account' });
      return await firebase.auth().signInWithPopup(provider);
    } catch (error) {
      if (
        error.email &&
        error.credential &&
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        const linkedProvider = new firebase.auth.OAuthProvider(
          providerLabel === 'microsoft.com' ? 'google.com' : 'microsoft.com'
        );
        linkedProvider.setCustomParameters({ login_hint: error.email });

        try {
          const { user } = await firebase
            .auth()
            .signInWithPopup(linkedProvider);
          if (user) {
            return await user.linkWithCredential(error.credential);
          }
          throw Error("Didn't manage to find the user with a linked account");
        } catch (error) {
          console.error(error);
          throw error;
        }
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  async signOut() {
    try {
      return await firebase.auth().signOut();
    } catch (error) {
      console.error(error);
      throw Error;
    }
  }

  get isAuthenticated() {
    return !!this.currentUser;
  }

  get currentUser() {
    return firebase.auth().currentUser;
  }

  waitUserLoad() {
    return new Promise<void>(resolve => {
      if (this._isLoaded) {
        resolve();
      } else {
        const loadingIntervalFunction = () => {
          // Check every 100ms if the app is loaded
          if (firebase.apps.length > 0) {
            clearInterval(loadingInterval);

            // Once the app is loaded, if after 1sec, the user is still not logged in,
            // we consider that there was no user logged in
            const loadedTimeout = setTimeout(() => {
              this._isLoaded = true;
              authUnsubscribe();
              resolve();
            }, 1500);

            // We wait for the user to get logged in again
            const authUnsubscribe = firebase.auth().onAuthStateChanged(user => {
              if (user) {
                this._isLoaded = true;
                clearTimeout(loadedTimeout);
                authUnsubscribe();
                resolve();
              }
            });
          }
        };
        const loadingInterval = setInterval(loadingIntervalFunction, 100);
        loadingIntervalFunction();
      }
    });
  }

  onAuthStateChanged(
    nextOrObserver:
      | firebase.Observer<any, Error>
      | ((a: firebase.User | null) => any),
    error?: ((a: firebase.auth.Error) => any) | undefined,
    completed?: firebase.Unsubscribe | undefined
  ) {
    return firebase.auth().onAuthStateChanged(nextOrObserver, error, completed);
  }
}

const auth = new Auth();

export { auth as default, auth };
