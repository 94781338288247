import React from 'react';
import Particles from 'react-tsparticles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: -1,
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    '& > canvas': {
      display: 'block'
    }
  }
}));

export const AnimatedBackground: React.FC = () => {
  const classes = useStyles();
  return (
    <Particles
      className={classes.root}
      params={{
        particles: {
          number: {
            value: 200,
            density: {
              enable: true,
              value_area: 800
            }
          },
          size: {
            value: 2
          }
        },
        interactivity: {
          // events: {
          //   onhover: {
          //     enable: false,
          //     mode: "bubble"
          //   },
          //   onclick: {
          //     enable: false,
          //     mode: "repulse"
          //   }
          // },
          // modes: {
          //   bubble: {
          //     distance: 250,
          //     duration: 2,
          //     size: 0,
          //     opacity: 0
          //   },
          //   repulse: {
          //     distance: 400,
          //     duration: 4
          //   }
          // }
        }
      }}
    />
  );
};

export default AnimatedBackground;
