import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Link,
  Typography,
  List,
  ListItem
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Copyright } from '../..';

const useStyles = makeStyles(theme => ({
  footerContainer: {
    backgroundColor: theme.palette.primary.light
  },
  footerListsContainer: {
    padding: theme.spacing(0, 0),
    margin: 'auto',
    maxWidth: '50%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 1000,
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4)
    }
  },
  footerList: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 3)
    }
  },
  footerListTitle: {
    fontWeight: 'bold',
    fontSize: '21px'
  },
  footerBranding: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  footerBrandingLogo: {
    height: 20,
    display: 'block',
    '& img': { height: '100%' },
    marginBottom: 10,
    [theme.breakpoints.up('sm')]: {
      height: 31
    }
  },
  footerBrandingText: {
    color: 'rgba(0, 0, 0, 0.5)'
  }
}));

export interface IFooter {
  privacyPolicy: string;
  DataProcessingAdendum: string;
  TermsOfService: string;
  supportEmail: string;
  instagram: string;
  linkedin: string;
}

interface IFooterListLink {
  url: string;
  newWindow?: boolean;
  label: string;
}

const FooterListLink: React.FC<IFooterListLink> = ({
  url,
  newWindow,
  label
}) => {
  const MuiLink = (
    <Link
      {...(newWindow
        ? {
            href: url,
            target: '_blank',
            rel: 'noreferrer'
          }
        : {
            component: RouterLink,
            to: url
          })}>
      {label}
    </Link>
  );
  return <ListItem disableGutters>{MuiLink}</ListItem>;
};

interface IFooterList {
  title: string;
  links: IFooterListLink[];
}

const Footer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation(['common', 'legal']);

  const FooterList: React.FC<IFooterList> = ({ title, links }) => (
    <Grid item xs={12} sm={3} className={classes.footerList}>
      <Typography variant="h6" className={classes.footerListTitle}>
        {title}
      </Typography>
      <List>
        {links.map((link, key) => (
          <FooterListLink {...link} key={key} />
        ))}
      </List>
    </Grid>
  );

  const FooterBranding = (
    <div className={classes.footerBranding}>
      <RouterLink to="/" className={classes.footerBrandingLogo}>
        <img src="/images/logo/logo-large.svg" alt="QuickCells logo" />
      </RouterLink>

      <Copyright />
    </div>
  );

  return (
    <footer className={classes.footerContainer}>
      <Grid container className={classes.footerListsContainer}>
        <FooterList
          title={t('Contact')}
          links={[
            {
              label:
                '6 rue des patriarches, 78320 Le Mesnil-Saint Denis, France',
              url:
                'https://www.google.com/maps/search/' +
                encodeURIComponent(
                  '6 rue des patriarches, 78320 Le Mesnil-Saint Denis, France'
                ),
              newWindow: true
            },
            // {
            //   label: "(+33) 00 00 00 00 00",
            //   url: "tel:(+33) 00 00 00 00 00",
            //   newWindow: true,
            // },
            {
              label: 'support@quickcells.co',
              url: 'mailto:support@quickcells.co',
              newWindow: true
            }
          ]}
        />
        <FooterList
          title={t('Useful links')}
          links={[
            {
              label: t('Home'),
              url: '/'
            },
            {
              label: t('Contact'),
              url: '/contact'
            },
            {
              label: t('Prices'),
              url: '/pricing'
            }
          ]}
        />
        <FooterList
          title={t('Legal')}
          links={[
            {
              label: t('legal:privacy policy'),
              url: '/legal/privacy'
            },
            {
              label: t('legal:terms of service'),
              url: '/legal/tos'
            },
            {
              label: t('legal:data processing adendum'),
              url: '/legal/dpa'
            }
          ]}
        />
        <FooterList
          title={t('Our services')}
          links={[
            {
              label: 'QuickCells Graphs',
              url: '/products/graphs'
            },
            {
              label: 'QuickCells Solvers',
              url: '/products/solvers'
            }
          ]}
        />
      </Grid>
      {FooterBranding}
    </footer>
  );
};

export { Footer as default, Footer };
