import PLAN_IDS from '../../config/PLAN_IDS.json';
import { getPaypalMode } from '../../config/getPaypalMode';

export interface PlanId {
  GRAPHS_MONTHLY_INDIVIDUAL: string;
  GRAPHS_MONTHLY_PROFESSIONAL: string;
  GRAPHS_YEARLY_INDIVIDUAL: string;
  GRAPHS_YEARLY_PROFESSIONAL: string;
  PREMIUM_MONTHLY_INDIVIDUAL: string;
  PREMIUM_MONTHLY_PROFESSIONAL: string;
  PREMIUM_YEARLY_INDIVIDUAL: string;
  PREMIUM_YEARLY_PROFESSIONAL: string;
  SOLVERS_MONTHLY_INDIVIDUAL: string;
  SOLVERS_MONTHLY_PROFESSIONAL: string;
  SOLVERS_YEARLY_INDIVIDUAL: string;
  SOLVERS_YEARLY_PROFESSIONAL: string;
}

const planIds = PLAN_IDS[getPaypalMode()] as PlanId;

/**
 * Gets Paypal billing plan id depending on its given reference
 * @param ref Plan reference
 * @returns Corresponding plan id
 */
export const getPlanId = (ref: keyof PlanId): string => planIds[ref] || '';
